import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { prepareAuthHeaders } from '../../utils/prepareAuthHeaders';

export const systemApi = createApi({
    reducerPath: 'systemApi',
    keepUnusedDataFor: 1,
    baseQuery: fetchBaseQuery({
        baseUrl: '/System',
        prepareHeaders: prepareAuthHeaders,
    }),
    endpoints: (builder) => ({
        getSemesters: builder.query({
            query: () => ({
                url: `/GetSemesters`,
                method: 'GET'
            }),
        }),
        
    }),
});

export const {
    useGetSemestersQuery,
} = systemApi;
