import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import {Navigate, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import Nav from "../layouts/dashboard/nav";

export default function HomePage() {
	const navigate = useNavigate();
	
	useEffect(() => {
		const roles = localStorage.getItem("roles") ?? '';
		const authorizedAt = localStorage.getItem("authorized-at") ?? '';
		const isEmployee = roles.split(',').find((item) => item === "employee") !== undefined
		let navigateTo = '/auth'
		console.log(roles);
		if (roles === null || roles === undefined || roles.length === 0 || roles === 'null') {
			navigateTo = '/auth';
		}
		else if (authorizedAt === null || authorizedAt === undefined || authorizedAt === '' || new Date(authorizedAt) < new Date('2024-12-09 16:30:00')) {
			localStorage.setItem('roles', null);
			localStorage.setItem('token', null);
			navigateTo = '/auth';
		}
		else {
			const isUmu = isRole("AttNewUmu", roles.split(','));
			const isMfc = isRole("AttNewMfc", roles.split(','));
			if (isUmu)
				navigateTo = '/umu-main';
			else if (isMfc)
				navigateTo = '/search-student';
			else
				navigateTo = isEmployee ? '/journalmain' : '/journalmainold';
		}

		console.log(`Переадресация на ${navigateTo}`);
		navigate(navigateTo, { replace: true });	
	});

	function isRole(name, roles) {
		return roles.find((item) => item === name) !== undefined;
	}
	
	return <Box>
		Загрузка...
	</Box>
}
