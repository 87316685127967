import { Helmet } from 'react-helmet-async';
// @mui
import {
    Card,
    Stack,
    Container,
    Typography,
    TableContainer,
    CardContent,
    Button,
    CircularProgress,
    Box,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    FormControl, InputLabel, InputAdornment, OutlinedInput,
} from '@mui/material';

import {useNavigate, useParams} from 'react-router-dom';

// components
import Scrollbar from '../components/scrollbar';
// sections
import { StepByStepTable } from "../sections/@dashboard/attestations";
import {GeneralLayout} from "../layouts/GeneralLayout";
import Label from "../components/label";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {useGetNextAttestationNumberQuery, usePostCreateAttestationMutation} from "../store/attestation/attestationApi";
import PageTitle from "../components/PageTitle";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {RemoveRedEye} from "@mui/icons-material";
import {
    useAcademicStipendWebReportMutation, useRetakesStatisticsOverTheUniversityWebReportMutation,
    useSessionResultsWebReportMutation,
    useSessionStatisticsWebReportMutation
} from "../store/reports/reportsApi";
import copyObject from "../utils/copyObject";
import {useGetFacultiesQuery, useGetGroupsByFacultyMutation} from "../store/faculty/facultyApi";
import {useGetSemestersQuery} from "../store/system/systemApi";

export default function RetakesStatisticsOverTheUniversityReportPage() {
    const {id} = useParams();
    const navigate = useNavigate();
    const [isSaving, setIsSaving] = useState(false);
    const [dataRequest] = useRetakesStatisticsOverTheUniversityWebReportMutation();
    const [data, setData] = useState([]);
    const [examsDate, setExamsDate] = useState(null);

    function formatDate(dateObj) {
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }

    function toDateString(date){
        if (date === null || date === undefined)
            return '';

        return new Date(date).toLocaleDateString("ru-RU");
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }

    const loadData = async () => {
        setIsSaving(true);

        const request = {
            examsDate
        };
        console.log(request);

        await dataRequest({data: request}).unwrap().then((fulfilled) => {
            if (fulfilled?.success) {
                setData(copyObject(fulfilled.faculties))
            } else {
                fulfilled.errors.map((item) => {
                    alert(item);
                    return 1;
                });
            }

            setIsSaving(false);
        }).catch((e) => {
            alert('Ошибка');
            setIsSaving(false);
        });
    };

    return (
        <GeneralLayout>
            <PageTitle title={`Статистика пересдач по университету`} />

            <Container>
                <Stack direction="column"
                       padding="120px 0px 0px 100px"
                       spacing={2}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {isSaving ? <CircularProgress /> : <></>}
                            Статистика пересдач по университету
                        </Typography>
                    </Stack>

                    <Card>
                        <CardContent>
                            {/* Фильтр по дате */}
                            <Box display="flex" alignItems="center" gap={2}>
                                <TextField
                                    label="Дата экзаменов"
                                    type="date"
                                    InputLabelProps={{ shrink: true }}
                                    size="small"
                                />
                                <Button variant="contained" startIcon={<RemoveRedEye />} onClick={loadData}>
                                    Просмотр
                                </Button>
                            </Box>
                        </CardContent>
                    </Card>

                    {/* Таблица */}
                    {data.map((facultyData, index) => (
                        <Box key={index} mb={4}>
                            <Typography variant="h6" gutterBottom>
                                {facultyData.facultyName}
                            </Typography>

                            {facultyData.courses?.map((course, courseIndex) => (
                                <Box key={courseIndex} mb={2}>
                                    <Typography variant="subtitle1" fontWeight="bold">
                                        {course.courseName} ({toDateString(course.fromDate)} - {toDateString(course.toDate)})
                                    </Typography>

                                    <Card>
                                        <TableContainer>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell rowSpan={2}>Предмет</TableCell>
                                                        <TableCell rowSpan={2}>Преподаватели</TableCell>
                                                        <TableCell rowSpan={2}>Дата пересдачи по графику</TableCell>
                                                        <TableCell colSpan={3}>Сведения о ведомости</TableCell>
                                                        <TableCell rowSpan={2}>Группа</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            <TableCell>№ ведомости</TableCell>
                                                            <TableCell>Дата создания</TableCell>
                                                            <TableCell>Дата закрытия</TableCell>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {course.rows.map((exam, examIndex) => (
                                                        <TableRow key={examIndex}>
                                                            <TableCell>{exam.disciplineName}</TableCell>
                                                            <TableCell>{exam.employees.join(', ')}</TableCell>
                                                            <TableCell>{toDateString(exam.eventDate)}</TableCell>
                                                            <TableCell>{exam.statementNumber ?? 'Ведомость не создана'}</TableCell>
                                                            <TableCell>{exam.statementCreationDate ?? '-'}</TableCell>
                                                            <TableCell>{exam.statementClosingDate ?? '-'}</TableCell>
                                                            <TableCell>{exam.group}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Card>
                                </Box>
                            ))}
                        </Box>
                    ))}
                    
                    <br />
                </Stack>
            </Container>
        </GeneralLayout>
    );
}