import {Helmet} from 'react-helmet-async';
// @mui
import {
    Card,
    Stack,
    Container,
    Typography,
    TableContainer, CardContent, Button, CircularProgress, Box,
} from '@mui/material';

import {useNavigate, useParams} from 'react-router-dom';

// components
import Scrollbar from '../components/scrollbar';
// sections
import {StepByStepTable} from "../sections/@dashboard/attestations";
import {GeneralLayout} from "../layouts/GeneralLayout";
import Label from "../components/label";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {useGetNextAttestationNumberQuery, usePostCreateAttestationMutation} from "../store/attestation/attestationApi";
import PageTitle from "../components/PageTitle";
import {useEditVirtualGroupMutation, useVirtualGroupDetailsQuery} from "../store/journal/journalApi";
import IconButton from "@mui/material/IconButton";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {PersonRemove, RemoveFromQueue, RemoveSharp} from "@mui/icons-material";

export default function EditVirtualGroupPage() {
    const {id} = useParams();
    const [refresh, setRefresh] = useState(false);
    const navigate = useNavigate();
    const [isSaving, setIsSaving] = useState(false);
    const [groupName, setGroupName] = useState('');
    const [totalHours, setTotalHours] = useState(0);
    const {data} = useVirtualGroupDetailsQuery(id);
    const [studentsForRemoval, setStudentsForRemoval] = useState([]);
    const [editVirtualGroupRequest] = useEditVirtualGroupMutation();

    useEffect(() => {
        if (data === null || data === undefined)
            return;

        setGroupName(data.data.virtualGroupName);
        setTotalHours(data.data.totalHours);
    }, [data]);

    function applyVisualChanges() {
        setRefresh(!refresh);
    }

    function formatDate(dateObj) {
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }

    function toDateString(date) {
        if (date === null || date === undefined)
            return '';

        return new Date(date).toLocaleDateString("ru-RU");
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }

    const handleSave = async () => {
        setIsSaving(true);
        const request = {
            newName: groupName,
            virtualGroupId: id,
            totalHours,
            studentsForRemoval: studentsForRemoval.map((item) => {
                return parseInt(item.key, 10)
            })
        };
        
        console.log(request)
        
        await editVirtualGroupRequest({data: request}).unwrap().then((fulfilled) => {
            if (fulfilled?.success) {
                navigate('/');
            } else {
                fulfilled.errors.map((item) => {
                    alert(item);
                    return 1;
                });

                setIsSaving(false);
            }
        }).catch(() => {
            setIsSaving(false);
        });
    };

    return (
        <GeneralLayout>
            <PageTitle title={`Редактировать сводную группу`}/>

            <Container>
                <Stack direction="column"
                       padding="120px 0px 0px 100px"
                       spacing={2}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {data === null || data === undefined ? <CircularProgress/> : <></>}
                            Редактировать сводную группу
                        </Typography>
                    </Stack>

                    <Card>
                        <CardContent>
                            <Stack spacing={2}>
                                <Stack direction={'row'} alignItems={'center'} spacing={2}>
                                    <Typography><strong>Наименование</strong></Typography>
                                    <TextField size={'small'}
                                               onInput={(event) => {
                                                   setGroupName(event.target.value)
                                               }}
                                               value={groupName}/>
                                </Stack>

                                <Stack direction={'row'} alignItems={'center'} spacing={2}>
                                    <Typography><strong>Часы</strong></Typography>
                                    <TextField size={'small'}
                                               onInput={(event) => {
                                                   setTotalHours(event.target.value)
                                               }}
                                               value={totalHours}/>
                                </Stack>
                                
                                <ol>
                                    {data?.data.students.map((student) => {
                                        return <li>{student.value} <IconButton color={'error'}
                                                                               disabled={studentsForRemoval.find((item) => item === student) !== undefined}
                                                                               onClick={() => {
                                                                                   studentsForRemoval.push(student);
                                                                                   applyVisualChanges();
                                                                               }}
                                                                               size={'small'}><PersonRemove/></IconButton>
                                        </li>
                                    })}
                                </ol>


                                <Stack justifyContent="space-between">
                                    {
                                        !isSaving
                                            ? <Button variant="contained"
                                                      disabled={data === null || data === undefined}
                                                      onClick={handleSave}>Сохранить</Button>
                                            : <CircularProgress/>
                                    }

                                    <Typography fontSize={'small'} align={'center'}>Обратите внимание, что после
                                        удаления студента из сводной группы нельзя будет по нему просмотреть историю
                                        посещений занятий, заполненных в рамках данной сводной группы</Typography>
                                </Stack>
                            </Stack>
                        </CardContent>
                    </Card>
                </Stack>
            </Container>
        </GeneralLayout>
    );
}