import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { prepareAuthHeaders } from '../../utils/prepareAuthHeaders';

export const reportsApi = createApi({
  reducerPath: 'reportsApi',
  keepUnusedDataFor: 1,
  baseQuery: fetchBaseQuery({
    baseUrl: '/Reports',
    prepareHeaders: prepareAuthHeaders,
  }),
  endpoints: (builder) => ({
    debtorStudentsWebReport: builder.mutation({
      query: ({ data }) => ({
        url: `/DebtorStudentsWebReport`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Post'],
    }),
    examsStatisticsWebReport: builder.mutation({
      query: ({ data }) => ({
        url: `/ExamsStatisticsWebReport`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Post'],
    }),
    financeFormsList: builder.query({
      query: () => ({
        url: `/FinanceFormsList`,
        method: 'GET'
      }),
    }),
    saveFinanceForms: builder.mutation({
      query: ({ data }) => ({
        url: `/SaveFinanceForms`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Post'],
    }),
    sessionResultsWebReport: builder.mutation({
      query: ({ data }) => ({
        url: `/SessionResultsWebReport`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Post'],
    }),
    academicStipendWebReport: builder.mutation({
      query: ({ data }) => ({
        url: `/AcademicStipendWebReport`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Post'],
    }),
    sessionStatisticsWebReport: builder.mutation({
      query: ({ data }) => ({
        url: `/SessionStatisticsWebReport`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Post'],
    }),
    overdueStatementsWebReport: builder.mutation({
      query: ({ data }) => ({
        url: `/OverdueStatementsWebReport`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Post'],
    }),
    retakesStatisticsOverTheUniversityWebReport: builder.mutation({
      query: ({ data }) => ({
        url: `/RetakesStatisticsOverTheUniversityWebReport`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Post'],
    }),
    averageEmployeeGradesOverTheDisciplinesWebReport: builder.mutation({
      query: ({ data }) => ({
        url: `/AverageEmployeeGradesOverTheDisciplinesWebReport`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Post'],
    }),
    practicesRetakesStatisticsWebReport: builder.mutation({
      query: () => ({
        url: `/PracticesRetakesStatisticsWebReport`,
        method: 'POST'
      }),
      invalidatesTags: ['Post'],
    }),
    notAllowedStudentsWebReport: builder.mutation({
      query: ({ data }) => ({
        url: `/NotAllowedStudentsWebReport`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Post'],
    }),
    sessionResultsAtTheMomentOfPassingByChairWebReport: builder.mutation({
      query: ({ data }) => ({
        url: `/SessionResultsAtTheMomentOfPassingByChairWebReport`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Post'],
    }),
    sessionResultsAtTheMomentOfPassingByFacultyGroupsWebReport: builder.mutation({
      query: ({ data }) => ({
        url: `/SessionResultsAtTheMomentOfPassingByFacultyGroupsWebReport`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Post'],
    }),
  }),
});

export const {
  useDebtorStudentsWebReportMutation,
  useExamsStatisticsWebReportMutation,
  useFinanceFormsListQuery,
  useSaveFinanceFormsMutation,
  useSessionResultsWebReportMutation,
  useAcademicStipendWebReportMutation,
  useSessionStatisticsWebReportMutation,
  useOverdueStatementsWebReportMutation,
  useRetakesStatisticsOverTheUniversityWebReportMutation,
  useAverageEmployeeGradesOverTheDisciplinesWebReportMutation,
  usePracticesRetakesStatisticsWebReportMutation,
  useNotAllowedStudentsWebReportMutation,
  useSessionResultsAtTheMomentOfPassingByChairWebReportMutation,
  useSessionResultsAtTheMomentOfPassingByFacultyGroupsWebReportMutation
} = reportsApi;
