import { Helmet } from 'react-helmet-async';
// @mui
import {
    Card,
    Stack,
    Container,
    Typography,
    TableContainer,
    CardContent,
    Button,
    CircularProgress,
    Box,
    ButtonGroup,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@mui/material';

import {useNavigate, useParams} from 'react-router-dom';

// components
import Scrollbar from '../components/scrollbar';
// sections
import { StepByStepTable } from "../sections/@dashboard/attestations";
import {GeneralLayout} from "../layouts/GeneralLayout";
import Label from "../components/label";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {useGetNextAttestationNumberQuery, usePostCreateAttestationMutation} from "../store/attestation/attestationApi";
import PageTitle from "../components/PageTitle";
import Paper from "@mui/material/Paper";
import {PrintOutlined, PrintSharp, RemoveRedEye} from "@mui/icons-material";
import {useExamsStatisticsWebReportMutation} from "../store/reports/reportsApi";
import copyObject from "../utils/copyObject";

export default function ExamsStatisticsReportPage() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [pdfIsLoading, setPdfIsLoading] = useState(false);
    const [copyOfData, setCopyOfData] = useState(null)
    const [reportDataRequest] = useExamsStatisticsWebReportMutation();
    const [examsDate, setExamsDate] = useState(null);
    // ExamsStatisticsReport

    function printPdf() {
        setPdfIsLoading(true);
        fetch(`/Reports/ExamsStatisticsReport?examsDate=${examsDate}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem('token')}`,
                    "semesterid": localStorage.getItem("selectedSemesterId")
                }
            }).then(response => {
            setPdfIsLoading(false);
            return response.blob();
        }).then(response => {
            const url = window.URL.createObjectURL(
                new Blob([response]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `Статистика экзаменов.pdf`,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
        })
    }

    function formatDate(dateObj) {
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }

    function toDateString(date){
        if (date === null || date === undefined)
            return '';

        return new Date(date).toLocaleDateString("ru-RU");
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }
    
    async function loadData() {
        setIsLoading(true);
        await reportDataRequest({data: {examsDate}}).unwrap().then((fulfilled) => {
            if (fulfilled?.success) {
                setCopyOfData(copyObject(fulfilled.faculties));
            } else {
                fulfilled.errors.map((item) => {
                    alert(item);
                    return 1;
                });
            }

            setIsLoading(false);
        });
    }
    
    return (
        <GeneralLayout>
            <PageTitle title={`Статистика экзаменов`} />

            <Container>
                <Stack direction="column"
                       padding="120px 0px 0px 100px"
                       spacing={2}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            Статистика экзаменов
                        </Typography>
                    </Stack>

                    <Card>
                        <CardContent>
                            <Stack spacing={4} direction={'row'} alignItems={'center'}>
                                <Typography variant={'h6'}>Экзамены по расписанию</Typography>

                                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                    <Typography>Дата экзаменов</Typography>
                                    <TextField size={'small'}
                                               onChange={(event) => setExamsDate(event.target.value)}
                                               type={'date'} />
                                    <ButtonGroup>
                                        <Button startIcon={isLoading ? <CircularProgress size={15} /> : <RemoveRedEye />} 
                                                disabled={examsDate === null || isLoading}
                                                onClick={() => {loadData()}}>Просмотр</Button>
                                        <Button startIcon={pdfIsLoading ? <CircularProgress size={15} /> : <PrintSharp />}
                                                disabled={examsDate === null || pdfIsLoading}
                                                onClick={() => {printPdf()}}>Печать</Button>
                                    </ButtonGroup>
                                </Stack>
                            </Stack>
                        </CardContent>
                    </Card>

                    {copyOfData !== null 
                    ? copyOfData.length === 0
                        ? <Typography align={'center'}>Список пуст</Typography>
                        : <Card>
                            <TableContainer>
                                <Table size={'small'} stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell rowSpan={2}>Предмет</TableCell>
                                            <TableCell rowSpan={2}>Преподаватели</TableCell>
                                            <TableCell colSpan={3} align={'center'}>Сведения о ведомости</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>№ ведомости</TableCell>
                                            <TableCell align={'center'}>Дата создания</TableCell>
                                            <TableCell align={'center'}>Дата закрытия</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {copyOfData?.sort((l,r) => l.facultyName > r.facultyName ? 1 : -1).map((faculty) => {
                                            return <>
                                                <TableRow style={{'backgroundColor': 'gray'}}>
                                                    <TableCell align={'center'} colSpan={5}><Typography fontWeight={1000} color={'white'}>{faculty.facultyName}</Typography></TableCell>
                                                </TableRow>
                                                {faculty.courses.sort((l,r) => l.courseName > r.courseName ? 1 : -1).map((course) => {
                                                    return <>
                                                        <TableRow style={{'backgroundColor': 'lightgray'}}>
                                                            <TableCell align={'center'} colSpan={5}><Typography fontWeight={500}>{course.courseName}</Typography></TableCell>
                                                        </TableRow>
                                                        {course.rows.sort((l,r) => l.disciplineName > r.disciplineName ? 1 : -1).map((row) => {
                                                            return <TableRow>
                                                                <TableCell>{row.disciplineName}</TableCell>
                                                                <TableCell>{row.employees.join(', ')}</TableCell>
                                                                <TableCell><Button variant={'text'}
                                                                                   target={'_blank'}
                                                                                   href={row.statementId > 0 ? `/statementresults/${row.statementId}` : null}>{row.statementNumber === null ? 'Нет ведомости' : `№${row.statementNumber}`}</Button></TableCell>
                                                                <TableCell align={'center'}>{toDateString(row.creationDate)}</TableCell>
                                                                <TableCell align={'center'}>{row.statementId <= 0 ? '' : row.closingDate === null ? "Не закрыта" : toDateString(row.closingDate)}</TableCell>
                                                            </TableRow>
                                                        })}
                                                    </>
                                                })}
                                            </>
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>        
                          </Card>
                    : <></>}
                    <br />
                </Stack>
            </Container>
        </GeneralLayout>
    );
}