import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { prepareAuthHeaders } from '../../utils/prepareAuthHeaders';

export const journalApi = createApi({
  reducerPath: 'journalApi',
  keepUnusedDataFor: 1,
  baseQuery: fetchBaseQuery({
    baseUrl: '/Journal',
    prepareHeaders: prepareAuthHeaders
  }),
  endpoints: (builder) => ({
    getListEmployeeDisciplines: builder.query({
      query: () => ({
        url: `/ListEmployeeDisciplines`,
        method: 'GET',
      }),
    }),
    getLessonCreationData: builder.query({
      query: ({ groupFlowTeamId, virtualGroupId }) => ({
        url: `/GetLessonCreationData`,
        method: 'GET',
        params: { groupFlowTeamId, virtualGroupId }
      }),
    }),
    getBindings: builder.query({
      query: () => ({
        url: `/GetBindings`,
        method: 'GET',
      }),
    }),
    getEmployeeBindings: builder.query({
      query: () => ({
        url: `/EmployeeBindings`,
        method: 'GET',
      }),
    }),
    getFacultyBindings: builder.query({
      query: () => ({
        url: `/FacultyBindings`,
        method: 'GET',
      }),
    }),
    getThemesListForEmployee: builder.query({
      query: () => ({
        url: `/ThemesListForEmployee`,
        method: 'GET',
      }),
    }),
    getSubGroups: builder.query({
      query: () => ({
        url: `/SubGroups`,
        method: 'GET',
      }),
    }),
    getSubgroupsOfGroupFlowBinding: builder.query({
      query: ({ courseId, disciplineId }) => ({
        url: `/SubgroupsOfGroupFlowBinding`,
        method: 'GET',
        params: { courseId, disciplineId },
      })
    }),
    getDisciplineGroupJournalBasics: builder.query({
      query: ({ courseId, disciplineId }) => ({
        url: `/DisciplineGroupJournalBasics`,
        method: 'GET',
        params: { courseId, disciplineId },
      })
    }),
    getJournalByGroup: builder.query({
      query: ({ groupFlowBindingId, virtualGroupId }) => ({
        url: `/GetJournalData`,
        method: 'GET',
        params: { groupFlowBindingId, virtualGroupId },
      })
    }),
    postSetStudentsBySubgroups: builder.mutation({
      query: ({ studentsData }) => ({
        url: `/SetStudentsBySubgroups`,
        method: 'POST',
        body: studentsData
      }),
      transformResponse: (response, meta, arg) => {
        return response;
      },
      invalidatesTags: ['Post'],
    }),
    postSaveOrUpdateLesson: builder.mutation({
      query: ({ formData }) => ({
        url: `/SaveOrUpdateLesson`,
        method: 'POST',
        body: {
          lessonDate: formData.lessonDate,
          pairNumber: formData.pairNumber,
          theme: formData.theme,
          groupFlowTeams: formData.groupFlowTeams.map((item) => {
            return {
              groupFlowTeamId: item.groupFlowTeamId,
              students: item.students.map((student) => {
                return {
                  studentId: student.studentId,
                  attended: student.attended,
                  grades: student.grades,
                  comment: student.comment
                }
              })
            };
          })
        }
      }),
      invalidatesTags: ['Post'],
    }),
    postSetAttendanceForStudent: builder.mutation({
      query: ({ lessonStudentDetailsId, value }) => ({
        url: `/SetAttendanceForStudent`,
        method: 'POST',
        body: {
          lessonStudentDetailsId,
          value
        },
      }),
      invalidatesTags: ['Post'],
    }),
    setCommentForStudentLesson: builder.mutation({
      query: ({ lessonStudentDetailsId, comment }) => ({
        url: `/SetCommentForStudentLesson`,
        method: 'POST',
        body: {
          lessonStudentDetailsId,
          comment
        },
      }),
      invalidatesTags: ['Post'],
    }),
    postEditGradeForStudent: builder.mutation({
      query: ({ grades }) => ({
        url: `/EditGradeForStudent`,
        method: 'POST',
        body: {
          grades
        },
      }),
      invalidatesTags: ['Post'],
    }),
    postEditExtraGradeForStudent: builder.mutation({
      query: ({ grades }) => ({
        url: `/EditExtraGradeForStudent`,
        method: 'POST',
        body: {
          grades
        },
      }),
      invalidatesTags: ['Post'],
    }),
    getStudentsDetailsOfGroupFlowBinding: builder.query({
      query: ({ disciplineId, lessonType, groupId }) => ({
        url: `/StudentsDetailsOfGroupFlowBinding`,
        method: 'GET',
        params: { disciplineId, lessonType, groupId },
      })
    }),
    saveVirtualGroup: builder.mutation({
      query: ({ data }) => ({
        url: `/SaveVirtualGroup`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Post'],
    }),
    getVirtualGroups: builder.query({
      query: () => ({
        url: `/GetVirtualGroups`,
        method: 'GET'
      })
    }),
    removeVirtualGroup: builder.mutation({
      query: ({ data }) => ({
        url: `/RemoveVirtualGroup`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Post'],
    }),
    virtualGroupDetails: builder.query({
      query: (virtualGroupId) => ({
        url: `/VirtualGroupDetails`,
        method: 'GET',
        params: {virtualGroupId}
      })
    }),
    editVirtualGroup: builder.mutation({
      query: ({ data }) => ({
        url: `/EditVirtualGroup`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Post'],
    }),
  }),
});

export const {
  useGetListEmployeeDisciplinesQuery,
  useGetThemesListForEmployeeQuery,
  useGetLessonCreationDataQuery,
  useGetBindingsQuery,
  useGetEmployeeBindingsQuery,
  useGetFacultyBindingsQuery,
  useGetSubgroupsOfGroupFlowBindingQuery,
  usePostSetStudentsBySubgroupsMutation,
  usePostSetAttendanceForStudentMutation,
  useSetCommentForStudentLessonMutation,
  usePostSaveOrUpdateLessonMutation,
  usePostEditExtraGradeForStudentMutation,
  usePostEditGradeForStudentMutation,
  useGetDisciplineGroupJournalBasicsQuery,
  useGetJournalByGroupQuery,
  useGetStudentsDetailsOfGroupFlowBindingQuery,
  useSaveVirtualGroupMutation,
  useGetVirtualGroupsQuery,
  useRemoveVirtualGroupMutation,
  useVirtualGroupDetailsQuery,
  useEditVirtualGroupMutation
} = journalApi;
