import { Helmet } from 'react-helmet-async';
// @mui
import {
    Card,
    Stack,
    Container,
    Typography,
    TableContainer,
    CardContent,
    Button,
    CircularProgress,
    Box,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Checkbox,
} from '@mui/material';

import {useNavigate, useParams} from 'react-router-dom';

// components
import Scrollbar from '../components/scrollbar';
// sections
import { StepByStepTable } from "../sections/@dashboard/attestations";
import {GeneralLayout} from "../layouts/GeneralLayout";
import Label from "../components/label";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {useGetNextAttestationNumberQuery, usePostCreateAttestationMutation} from "../store/attestation/attestationApi";
import PageTitle from "../components/PageTitle";
import {useFinanceFormsListQuery, useSaveFinanceFormsMutation} from "../store/reports/reportsApi";
import copyObject from "../utils/copyObject";
import {Save} from "@mui/icons-material";

export default function EditFinanceFormsPage() {
    const {id} = useParams();
    const navigate = useNavigate();
    const [isSaving, setIsSaving] = useState(false);
    const {data} = useFinanceFormsListQuery();
    const [copyOfData, setCopyOfData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [saveRequest] = useSaveFinanceFormsMutation();
    
    useEffect(() => {
        if (data === null || data === undefined) {
            return;
        }
        
        setCopyOfData(copyObject(data?.data))
    }, [data]);
    
    function applyVisualChanges() {
        setRefresh(!refresh);
    }

    function formatDate(dateObj) {
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }

    function toDateString(date){
        if (date === null || date === undefined)
            return '';

        return new Date(date).toLocaleDateString("ru-RU");
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }

    const handleSave = async () => {
        setIsSaving(true);

        await saveRequest({data: {financeForms: copyOfData}}).unwrap().then((fulfilled) => {
            if (fulfilled?.success) {
                alert('Данные успешно сохранены')
            } else {
                fulfilled.errors.map((item) => {
                    alert(item);
                    return 1;
                });
            }

            setIsSaving(false);
        }).catch((e) => {
            setIsSaving(false);
        });
    };

    return (
        <GeneralLayout>
            <PageTitle title={`Редактировать выплаты`} />

            <Container>
                <Stack direction="column"
                       padding="120px 0px 0px 100px"
                       spacing={2}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {data === null || data === undefined ? <CircularProgress /> : <></>}
                            Редактировать выплаты
                        </Typography>
                    </Stack>

                    <Card>
                        <Table size={'small'}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Наименование</TableCell>
                                    <TableCell>Бюджет</TableCell>
                                    <TableCell>Квота</TableCell>
                                    <TableCell>Оценка 2</TableCell>
                                    <TableCell>Оценка 3</TableCell>
                                    <TableCell>Оценка 4</TableCell>
                                    <TableCell>Оценки 4 и 5</TableCell>
                                    <TableCell>Оценка 5</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {copyOfData?.sort((l,r) => l.financeFormName > r.financeFormName ? 1 : -1).map((item) => {
                                    return <TableRow>
                                        <TableCell>{item.financeFormName}</TableCell>
                                        <TableCell><Checkbox checked={item.isBudget} onChange={(event, checked) => {item.isBudget = checked; applyVisualChanges()}}/></TableCell>
                                        <TableCell><Checkbox checked={item.isQuota} onChange={(event, checked) => {item.isQuota = checked; applyVisualChanges()}}/></TableCell>
                                        <TableCell>
                                            <TextField size={'small'} 
                                                       defaultValue={item.grade2Stipend} 
                                                       type={'number'}
                                                       onChange={(event) => {item.grade2Stipend = event.target.value}}/>
                                        </TableCell>
                                        <TableCell>
                                            <TextField size={'small'}
                                                       defaultValue={item.grade3Stipend}
                                                       type={'number'}
                                                       onChange={(event) => {item.grade3Stipend = event.target.value}}/>
                                        </TableCell>
                                        <TableCell>
                                            <TextField size={'small'}
                                                       defaultValue={item.grade4Stipend}
                                                       type={'number'}
                                                       onChange={(event) => {item.grade4Stipend = event.target.value}}/>
                                        </TableCell>
                                        <TableCell>
                                            <TextField size={'small'}
                                                       defaultValue={item.grade4And5Stipend}
                                                       type={'number'}
                                                       onChange={(event) => {item.grade4And5Stipend = event.target.value}}/>
                                        </TableCell>
                                        <TableCell>
                                            <TextField size={'small'}
                                                       defaultValue={item.grade5Stipend}
                                                       type={'number'}
                                                       onChange={(event) => {item.grade5Stipend = event.target.value}}/>
                                        </TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                        
                        <CardContent>
                            <Stack spacing={2}>
                                <Stack justifyContent="space-between">
                                    {
                                        !isSaving
                                            ? <Button variant="contained"
                                                      disabled={data === null || data === undefined}
                                                      onClick={handleSave}>Сохранить</Button>
                                            : <CircularProgress/>
                                    }
                                </Stack>
                            </Stack>
                        </CardContent>
                    </Card>
                </Stack>
            </Container>
        </GeneralLayout>
    );
}