import {Helmet} from 'react-helmet-async';
// @mui
import {
    Card,
    Stack,
    Container,
    Typography,
    TableContainer,
    CardContent,
    Button,
    CircularProgress,
    Box,
    InputLabel,
    FormControlLabel,
    Radio,
    RadioGroup,
    Divider, Checkbox, ButtonGroup, Table, TableHead, TableRow, TableCell, TableBody, CardHeader,
} from '@mui/material';

import {useNavigate, useParams} from 'react-router-dom';

// components
import Scrollbar from '../components/scrollbar';
// sections
import {StepByStepTable} from "../sections/@dashboard/attestations";
import {GeneralLayout} from "../layouts/GeneralLayout";
import Label from "../components/label";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {useGetNextAttestationNumberQuery, usePostCreateAttestationMutation} from "../store/attestation/attestationApi";
import PageTitle from "../components/PageTitle";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {CheckBox, RemoveRedEye, Web} from "@mui/icons-material";
import PrintIcon from "@mui/icons-material/Print";
import {useDebtorStudentsWebReportMutation} from "../store/reports/reportsApi";
import copyObject from "../utils/copyObject";

export default function DebtorStudentsReportPage() {
    const {id} = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [webDataRequest, setWebDataRequest] = useState({
        byDate: new Date(),
        filterType: 1,
        daysAfterSession: null,
        presentationType: 0,
        educationalForm: 0,
        includeNotClosedStatements: false
    });
    const [copyOfData, setCopyOfData] = useState(null);
    const [dataRequest] = useDebtorStudentsWebReportMutation();
    const [presentationType, setPresentationType] = useState(0);
    let reportType = 'by-date';
    let byDate = null;
    let daysAfterSession = null;

    function formatDate(dateObj) {
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }

    function toDateString(date) {
        if (date === null || date === undefined)
            return '';

        return new Date(date).toLocaleDateString("ru-RU");
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }

    function onReportTypeChanged(value) {
        reportType = value;
        
        webDataRequest.filterType = reportType === 'by-date' ? 1 : 2;
    }
    
    async function loadData() {
        setIsLoading(true);
        await dataRequest({data: webDataRequest}).unwrap().then((fulfilled) => {
                if (fulfilled?.success) {
                    setCopyOfData(copyObject(fulfilled.faculties));
                } else {
                    fulfilled.errors.map((item) => {
                        alert(item);
                        return 1;
                    });
                }
                
                setIsLoading(false);
            });
    }
    
    function daysAfterSessionChanged(value) {
        daysAfterSession = parseInt(value, 10);
        onReportTypeChanged('days-after-session');
        webDataRequest.daysAfterSession = daysAfterSession;
    }
    
    function byDateChanged(value) {
        byDate = value;
        webDataRequest.byDate = value;
    }
    
    function getCurrentDate() {
        const date = new Date();
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    }
    
    function getDebtorsPercentage(group) {
        return (group.debtorStudents.length * 100 / group.numberOfStudentsInGroup).toFixed(1)
    }
    
    function getAveragePercentage(groups) {
        let sumOfDebtors = 0;
        groups.forEach((item) => {
            sumOfDebtors += item.debtorStudents.length;
        });
        
        let totalNumberOfStudents = 0;
        groups.forEach((item) => {
            totalNumberOfStudents += item.numberOfStudentsInGroup;
        });
        
        if (totalNumberOfStudents === 0) {
            console.log(`no students`)    
        }
        
        return (sumOfDebtors * 100 / totalNumberOfStudents).toFixed(1)
    }
    
    function sum(arr) {
        let temp = 0;
        arr.forEach((item) => {temp += item});
        
        return temp;
    }
    
    function numberOfDebtorsOfFaculty(faculty) {
        let totalNumberOfDebtors = 0;
        faculty.courses.forEach((course) => {
            totalNumberOfDebtors += sum(course.groups.map((group) => group.debtorStudents.length))
        });
        
        return totalNumberOfDebtors;
    }

    function numberOfDebtorsOfAllFaculties() {
        let totalNumberOfDebtors = 0;
        copyOfData?.forEach((faculty) => {
            faculty.courses.forEach((course) => {
                totalNumberOfDebtors += sum(course.groups.map((group) => group.debtorStudents.length))
            });
        });

        return totalNumberOfDebtors;
    }
    
    function percentageOfDebtorsOfFaculty(faculty) {
        const groups = [];
        faculty.courses.forEach((course) => {
            course.groups.forEach((group) => {
                groups.push(group);
            })
        });
        
        return getAveragePercentage(groups);
    }

    function percentageOfDebtorsOfAllFaculties() {
        const groups = [];
        copyOfData?.forEach((faculty) => {
            faculty.courses.forEach((course) => {
                course.groups.forEach((group) => {
                    groups.push(group);
                })
            });
        });

        return getAveragePercentage(groups);
    }
    
    function numberOfDebtorsOfFacultyWithFilters(faculty, groupType, courseNumber) {
        let numberOfDebtors = 0;
        faculty.courses.filter((course) => course.courseName.startsWith(courseNumber.toString()))
            .forEach((course) => {
                course.groups.filter((group) => group.groupType.toLowerCase() === groupType.toLowerCase())
                    .forEach((group) => {
                       numberOfDebtors += group.debtorStudents.length; 
                    });
            });
        
        return numberOfDebtors;
    }
    
    function totalNumberOfDebtorsOfCourse(course) {
        let temp = 0;
        course.groups.forEach((item) => {
            temp += item.debtorStudents.length;
        });
        
        return temp;
    }

    return (
        <GeneralLayout>
            <PageTitle title={`Студенты-задолжники`}/>

            <Container>
                <Stack direction="column"
                       padding="120px 0px 0px 100px"
                       spacing={2}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            Студенты-задолжники
                        </Typography>
                    </Stack>

                    <Card>
                        <CardContent>
                            <Stack spacing={1}>
                                <RadioGroup defaultValue="by-date"
                                            onChange={(event) => onReportTypeChanged(event.target.value)}>
                                    <Stack spacing={1}>
                                        <Stack spacing={1} direction={'row'}>
                                            <Paper elevation={0}>
                                                <FormControlLabel control={<Radio/>}
                                                                  value={'by-date'}
                                                                  label={'Сбор статистики на указанную дату'}/>

                                                <TextField type={'date'}
                                                           defaultValue={getCurrentDate()}
                                                           onChange={(event) => { byDateChanged(event.target.value) }}
                                                           placeholder={'Дата экзаменов'}
                                                           size={'small'}/>
                                            </Paper>

                                            <Paper spacing={1} direction={'row'}>
                                                <FormControlLabel control={<Radio/>}
                                                                  value={'days-after-session'}
                                                                  label={'По истечении дней после окончания сессии'}/>

                                                <TextField type={'number'}
                                                           placeholder={'Кол-во дней'}
                                                           onChange={(event) => { daysAfterSessionChanged(event.target.value) }}
                                                           size={'small'}/>
                                            </Paper>

                                            <ButtonGroup orientation={'vertical'} disabled={isLoading}>
                                                {/* <Button variant={'outlined'} */}
                                                {/*        startIcon={<PrintIcon/>} */}
                                                {/*        size={'small'}>Печать</Button> */}
                                                <Button variant={'contained'} 
                                                        onClick={() => {loadData()}}
                                                        startIcon={isLoading ? <CircularProgress size={15}/> : <RemoveRedEye/>}
                                                        size={'small'}>Просмотр</Button>
                                            </ButtonGroup>
                                        </Stack>

                                        <Divider/>

                                        <Stack direction={'row'} spacing={3}>
                                            <Stack>
                                                <Typography><strong>Тип представления</strong></Typography>
                                                <Select size={'small'} defaultValue={0} onChange={(event) => { webDataRequest.presentationType = event.target.value; setPresentationType(event.target.value) }}>
                                                    <MenuItem value={0}>Количество студентов</MenuItem>
                                                    <MenuItem value={1}>ФИО студентов</MenuItem>
                                                </Select>
                                            </Stack>

                                            <Stack>
                                                <Typography><strong>Форма обучения</strong></Typography>
                                                <Select size={'small'} defaultValue={0} onChange={(event) => { webDataRequest.educationalForm = event.target.value }}>
                                                    <MenuItem value={0}>Все</MenuItem>
                                                    <MenuItem value={1}>Заочная</MenuItem>
                                                    <MenuItem value={2}>Очная</MenuItem>
                                                    <MenuItem value={3}>Очно-Заочная</MenuItem>
                                                </Select>
                                            </Stack>

                                            <FormControlLabel control={<Checkbox/>}
                                                              onChange={(event, checked) => {webDataRequest.includeNotClosedStatements = checked}}
                                                              label={`Учитывать открытые ведомости`}/>
                                        </Stack>

                                        {isLoading
                                        ? <><Divider />

                                                <Typography align={'center'} color={'gray'}>Сбор статистики может занять некоторое время</Typography></>
                                        : <></>}
                                    </Stack>
                                </RadioGroup>
                            </Stack>
                        </CardContent>
                    </Card>
                    
                    <Divider />

                    {copyOfData !== null && copyOfData !== undefined && copyOfData.length > 0
                    ? presentationType === 0
                        ? <><Typography variant={'h5'}>Общая статистика по факультетам</Typography>
                                <Card>
                                    <TableContainer>
                                        <Table size={'small'} stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell rowSpan={3}>Факультет</TableCell>
                                                    <TableCell rowSpan={3} align={'center'}>Количество задолжников</TableCell>
                                                    <TableCell rowSpan={3} align={'center'}>Процент задолжников</TableCell>
                                                    <TableCell colSpan={20} align={'center'}>Число задолжников по курсам</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell style={{'border-left': '1px solid gray'}} colSpan={4}
                                                               align={'center'}>1</TableCell>
                                                    <TableCell style={{'border-left': '1px solid gray'}} colSpan={4}
                                                               align={'center'}>2</TableCell>
                                                    <TableCell style={{'border-left': '1px solid gray'}} colSpan={3}
                                                               align={'center'}>3</TableCell>
                                                    <TableCell style={{'border-left': '1px solid gray'}} colSpan={3}
                                                               align={'center'}>4</TableCell>
                                                    <TableCell style={{'border-left': '1px solid gray'}} colSpan={3}
                                                               align={'center'}>5</TableCell>
                                                    <TableCell style={{'border-left': '1px solid gray'}} colSpan={3}
                                                               align={'center'}>6</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell style={{'border-left': '1px solid gray'}}>С</TableCell>
                                                    <TableCell>Б</TableCell>
                                                    <TableCell>М</TableCell>
                                                    <TableCell>П</TableCell>

                                                    <TableCell style={{'border-left': '1px solid gray'}}>С</TableCell>
                                                    <TableCell>Б</TableCell>
                                                    <TableCell>М</TableCell>
                                                    <TableCell>П</TableCell>

                                                    <TableCell style={{'border-left': '1px solid gray'}}>С</TableCell>
                                                    <TableCell>Б</TableCell>
                                                    <TableCell>П</TableCell>

                                                    <TableCell style={{'border-left': '1px solid gray'}}>С</TableCell>
                                                    <TableCell>Б</TableCell>
                                                    <TableCell>П</TableCell>

                                                    <TableCell style={{'border-left': '1px solid gray'}}>С</TableCell>
                                                    <TableCell>Б</TableCell>
                                                    <TableCell>П</TableCell>

                                                    <TableCell style={{'border-left': '1px solid gray'}}>С</TableCell>
                                                    <TableCell>Б</TableCell>
                                                    <TableCell>П</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {copyOfData?.sort((l,r) => l.facultyName > r.facultyName).map((faculty) => {
                                                    return <TableRow>
                                                        <TableCell>{faculty.facultyName}</TableCell>
                                                        <TableCell align={'center'}>{numberOfDebtorsOfFaculty(faculty)}</TableCell>
                                                        <TableCell align={'center'}>{percentageOfDebtorsOfFaculty(faculty)}%</TableCell>

                                                        <TableCell>{numberOfDebtorsOfFacultyWithFilters(faculty, 'С', 1)}</TableCell>
                                                        <TableCell>{numberOfDebtorsOfFacultyWithFilters(faculty, 'Б', 1)}</TableCell>
                                                        <TableCell>{numberOfDebtorsOfFacultyWithFilters(faculty, 'М', 1)}</TableCell>
                                                        <TableCell>{numberOfDebtorsOfFacultyWithFilters(faculty, 'П', 1)}</TableCell>

                                                        <TableCell>{numberOfDebtorsOfFacultyWithFilters(faculty, 'С', 2)}</TableCell>
                                                        <TableCell>{numberOfDebtorsOfFacultyWithFilters(faculty, 'Б', 2)}</TableCell>
                                                        <TableCell>{numberOfDebtorsOfFacultyWithFilters(faculty, 'М', 2)}</TableCell>
                                                        <TableCell>{numberOfDebtorsOfFacultyWithFilters(faculty, 'П', 2)}</TableCell>

                                                        <TableCell>{numberOfDebtorsOfFacultyWithFilters(faculty, 'С', 3)}</TableCell>
                                                        <TableCell>{numberOfDebtorsOfFacultyWithFilters(faculty, 'Б', 3)}</TableCell>
                                                        <TableCell>{numberOfDebtorsOfFacultyWithFilters(faculty, 'П', 3)}</TableCell>

                                                        <TableCell>{numberOfDebtorsOfFacultyWithFilters(faculty, 'С', 4)}</TableCell>
                                                        <TableCell>{numberOfDebtorsOfFacultyWithFilters(faculty, 'Б', 4)}</TableCell>
                                                        <TableCell>{numberOfDebtorsOfFacultyWithFilters(faculty, 'П', 4)}</TableCell>

                                                        <TableCell>{numberOfDebtorsOfFacultyWithFilters(faculty, 'С', 5)}</TableCell>
                                                        <TableCell>{numberOfDebtorsOfFacultyWithFilters(faculty, 'Б', 5)}</TableCell>
                                                        <TableCell>{numberOfDebtorsOfFacultyWithFilters(faculty, 'П', 5)}</TableCell>

                                                        <TableCell>{numberOfDebtorsOfFacultyWithFilters(faculty, 'С', 6)}</TableCell>
                                                        <TableCell>{numberOfDebtorsOfFacultyWithFilters(faculty, 'Б', 6)}</TableCell>
                                                        <TableCell>{numberOfDebtorsOfFacultyWithFilters(faculty, 'П', 6)}</TableCell>
                                                    </TableRow>
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Card>

                                <Stack fullWidth>
                                    <Typography align={'right'}><strong>По всем факультетам*: </strong> {numberOfDebtorsOfAllFaculties()} чел. ({percentageOfDebtorsOfAllFaculties()}%)</Typography>
                                    <Typography align={'right'} fontSize={'small'}>* в расчёт берутся факультеты из списка ниже</Typography>
                                </Stack>

                                <Divider />

                                <Typography variant={'h5'}>Подробная статистика</Typography>

                                {copyOfData?.sort((l,r) => l.facultyName > r.facultyName ? 1 : -1).map((item) => {
                                    return <Card>
                                        <CardContent>
                                            <Typography variant={'h6'}>Факультет {item.facultyName}</Typography>
                                        </CardContent>
                                        <Table size={'small'}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Курс</TableCell>
                                                    <TableCell align={'center'}>Группа</TableCell>
                                                    <TableCell align={'center'}>Кол-во задолжников</TableCell>
                                                    <TableCell align={'center'}>Процент задолжников</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {item.courses.sort((l,r) => l.courseName > r.courseName ? 1 : -1).map((course) => {
                                                    return <>
                                                        <TableRow>
                                                            <TableCell rowSpan={course.groups.length+1}>{`${course.courseName} (${toDateString(course.from)} - ${toDateString(course.to)})`}</TableCell>
                                                            <TableCell align={'center'}>{course.groups.sort((l,r) => l.groupName > r.groupName ? 1 : -1)[0].groupName}</TableCell>
                                                            <TableCell align={'center'}>{course.groups.sort((l,r) => l.groupName > r.groupName ? 1 : -1)[0].debtorStudents.length}</TableCell>
                                                            <TableCell align={'center'}>{getDebtorsPercentage(course.groups.sort((l,r) => l.groupName > r.groupName ? 1 : -1)[0])}%</TableCell>
                                                        </TableRow>
                                                        {course.groups.sort((l,r) => l.groupName > r.groupName ? 1 : -1).slice(1).map((group) => {
                                                            return <TableRow>
                                                                <TableCell align={'center'}>{group.groupName}</TableCell>
                                                                <TableCell align={'center'}>{group.debtorStudents.length}</TableCell>
                                                                <TableCell align={'center'}>{getDebtorsPercentage(group)}%</TableCell>
                                                            </TableRow>
                                                        })}
                                                        <TableRow style={{'background-color': 'lightgray'}}>
                                                            <TableCell align={'center'}><strong>ВСЕГО</strong></TableCell>
                                                            <TableCell align={'center'}><strong>{sum(course.groups.map((group) => {
                                                                return group.debtorStudents.length;
                                                            }))}</strong></TableCell>
                                                            <TableCell align={'center'}><strong>{getAveragePercentage(course.groups)}%</strong></TableCell>
                                                        </TableRow>
                                                    </>
                                                })}
                                                <TableRow style={{'background-color': 'lightgray'}}>
                                                    <TableCell colSpan={2}><strong>Всего по факультету</strong></TableCell>
                                                    <TableCell align={'center'}><strong>{numberOfDebtorsOfFaculty(item)}</strong></TableCell>
                                                    <TableCell align={'center'}><strong>{percentageOfDebtorsOfFaculty(item)}%</strong></TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </Card>
                                })}
                            </>
                        : <>
                                {copyOfData?.sort((l,r) => l.facultyName > r.facultyName ? 1 : -1).map((item) => {
                                    return <Card>
                                        <CardContent>
                                            <Typography variant={'h6'}>Факультет {item.facultyName}</Typography>
                                        </CardContent>
                                        <Table size={'small'}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Курс</TableCell>
                                                    <TableCell align={'center'}>Группа</TableCell>
                                                    <TableCell align={'center'}>Студент</TableCell>
                                                    <TableCell align={'center'}>Несданные дисциплины</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {item.courses.sort((l,r) => l.courseName > r.courseName ? 1 : -1).map((course) => {
                                                    return <>
                                                        <TableRow>
                                                            <TableCell rowSpan={totalNumberOfDebtorsOfCourse(course)}>{`${course.courseName} (${toDateString(course.from)} - ${toDateString(course.to)})`}</TableCell>
                                                            <TableCell align={'center'} rowSpan={course.groups.sort((l,r) => l.groupName > r.groupName ? 1 : -1)[0].debtorStudents.length}>{course.groups.sort((l,r) => l.groupName > r.groupName ? 1 : -1)[0].groupName}</TableCell>
                                                            <TableCell align={'center'}>{course.groups.sort((l,r) => l.groupName > r.groupName ? 1 : -1)[0].debtorStudents.sort((l,r) => l.fullName > r.fullName ? 1 : -1)[0].fullName}</TableCell>
                                                            <TableCell align={'center'}>{course.groups.sort((l,r) => l.groupName > r.groupName ? 1 : -1)[0].debtorStudents.sort((l,r) => l.fullName > r.fullName ? 1 : -1)[0].disciplines.join(', ')}</TableCell>
                                                        </TableRow>
                                                        {course.groups.sort((l,r) => l.groupName > r.groupName ? 1 : -1)[0].debtorStudents.sort((l,r) => l.fullName > r.fullName ? 1 : -1).slice(1).map((student) => {
                                                            return <TableRow>
                                                                <TableCell align={'center'}>{student.fullName}</TableCell>
                                                                <TableCell align={'center'}>{student.disciplines.join(', ')}</TableCell>
                                                            </TableRow>
                                                        })}
                                                        {course.groups.sort((l,r) => l.groupName > r.groupName ? 1 : -1).slice(1).map((group) => {
                                                            return <>
                                                                <TableRow>
                                                                    <TableCell align={'center'} rowSpan={group.debtorStudents.length}>{group.groupName}</TableCell>
                                                                    <TableCell align={'center'}>{group.debtorStudents.sort((l,r) => l.fullName > r.fullName ? 1 : -1)[0].fullName}</TableCell>
                                                                    <TableCell align={'center'}>{group.debtorStudents.sort((l,r) => l.fullName > r.fullName ? 1 : -1)[0].disciplines.join(', ')}</TableCell>
                                                                </TableRow>
                                                                {group.debtorStudents.sort((l,r) => l.fullName > r.fullName ? 1 : -1).slice(1).map((student) => {
                                                                    return <TableRow>
                                                                        <TableCell align={'center'}>{student.fullName}</TableCell>
                                                                        <TableCell align={'center'}>{student.disciplines.join(', ')}</TableCell>
                                                                    </TableRow>
                                                                })}
                                                            </>
                                                        })}
                                                    </>
                                                })}
                                            </TableBody>
                                        </Table>
                                    </Card>
                                })}
                            </>
                    : <Typography fontSize={'large'} align={'center'}>{copyOfData === null ? 'Выберите необходимые фильтры, после чего нажмите на кнопку "Просмотр"' : 'Нет данных по выбранным фильтрам'}</Typography>}

                    
                    
                    <br />
                </Stack>
            </Container>
        </GeneralLayout>
    )
        ;
}