import { Helmet } from 'react-helmet-async';
// @mui
import {
    Card,
    Stack,
    Container,
    Typography,
    TableContainer,
    CardContent,
    Button,
    CircularProgress,
    Box,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    FormControl, InputLabel, InputAdornment, OutlinedInput,
} from '@mui/material';

import {useNavigate, useParams} from 'react-router-dom';

// components
import Scrollbar from '../components/scrollbar';
// sections
import { StepByStepTable } from "../sections/@dashboard/attestations";
import {GeneralLayout} from "../layouts/GeneralLayout";
import Label from "../components/label";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {useGetNextAttestationNumberQuery, usePostCreateAttestationMutation} from "../store/attestation/attestationApi";
import PageTitle from "../components/PageTitle";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {RemoveRedEye} from "@mui/icons-material";
import {
    useAcademicStipendWebReportMutation,
    useSessionResultsWebReportMutation,
    useSessionStatisticsWebReportMutation
} from "../store/reports/reportsApi";
import copyObject from "../utils/copyObject";
import {useGetFacultiesQuery, useGetGroupsByFacultyMutation} from "../store/faculty/facultyApi";
import {useGetSemestersQuery} from "../store/system/systemApi";
import {Alert} from "@mui/lab";

export default function SessionStatisticsReportPage() {
    const {id} = useParams();
    const navigate = useNavigate();
    const [isSaving, setIsSaving] = useState(false);
    const [dataRequest] = useSessionStatisticsWebReportMutation();
    const [data, setData] = useState([]);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [minPercentage, setMinPercentage] = useState(0);
    const [educationForm, setEducationForm] = useState(null);

    function formatDate(dateObj) {
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }

    function toDateString(date){
        if (date === null || date === undefined)
            return '';

        return new Date(date).toLocaleDateString("ru-RU");
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }

    const loadData = async () => {
        if (!fromDate || !toDate) {
            alert('Заполните даты');
            return;
        }
        
        console.log(fromDate)
        if (new Date(fromDate) < new Date('2024-09-01') || new Date(toDate) < new Date('2024-09-01')) {
            alert('Временно невозможно выбрать дату менее 1 сен. 2024г.')
            return;
        }
        
        
        setIsSaving(true);

        const request = {
            fromDate,
            toDate,
            minPercentageOfNotPassedStudents: minPercentage,
            educationForm
        };
        console.log(request);

        await dataRequest({data: request}).unwrap().then((fulfilled) => {
            if (fulfilled?.success) {
                setData(copyObject(fulfilled.faculties))
                console.log(`received ${fulfilled.faculties.length} faculties`)
            } else {
                fulfilled.errors.map((item) => {
                    alert(item);
                    return 1;
                });
            }

            setIsSaving(false);
        }).catch((e) => {
            alert('Ошибка');
            setIsSaving(false);
        });
    };

    return (
        <GeneralLayout>
            <PageTitle title={`Статистика сессии`} />

            <Container>
                <Stack direction="column"
                       padding="120px 0px 0px 100px"
                       spacing={2}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {isSaving ? <CircularProgress /> : <></>}
                            Статистика сессии
                        </Typography>
                    </Stack>
                    
                    <Card>
                        <Alert color={'warning'}>
                            Выбор минимальной даты временно ограничен до 01.09.2024
                        </Alert>
                        
                        <CardContent>
                            <Stack direction={'row'} spacing={2} alignItems={'end'} alignContent={'space-between'}>
                                <Stack>
                                    <Typography fontSize={'small'}>Дата начала периода</Typography>
                                    <TextField type={'date'}
                                               InputProps={{inputProps: { min: "2024-09-01"} }}
                                               size={'small'} 
                                               onChange={(event) => setFromDate(event.target.value)}/>
                                </Stack>

                                <Stack>
                                    <Typography fontSize={'small'}>Дата конца периода</Typography>
                                    <TextField type={'date'}
                                               InputProps={{inputProps: { min: "2024-09-01"} }}
                                               size={'small'} 
                                               onChange={(event) => setToDate(event.target.value)}/>
                                </Stack>

                                <Stack>
                                    <Typography fontSize={'small'}>Мин. процент неуспевающих</Typography>
                                    <OutlinedInput type={'number'} 
                                               defaultValue={0}
                                               size={'small'}
                                               endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                               onChange={(event) => setMinPercentage(parseInt(event.target.value, 10))}/>
                                </Stack>

                                <Stack>
                                    <Typography fontSize={'small'}>Форма обучения</Typography>
                                    <Select size={'small'}
                                            defaultValue={0}
                                            onChange={(event) => setEducationForm(event.target.value === 0 ? null : event.target.value)}>
                                        <MenuItem key={0} value={0}>Все</MenuItem>
                                        <MenuItem key={1} value={1}>Заочная</MenuItem>
                                        <MenuItem key={2} value={2}>Очная</MenuItem>
                                        <MenuItem key={3} value={3}>Очно-Заочная</MenuItem>
                                    </Select>
                                </Stack>
                            </Stack>

                            <Stack>
                                <br />
                                <Button variant={'contained'}
                                        startIcon={<RemoveRedEye />}
                                        onClick={loadData}
                                        disabled={isSaving}>Загрузить</Button>
                            </Stack>
                        </CardContent>
                    </Card>

                    {data !== null && data !== undefined && data.length > 0
                        ? <>
                            <Card>
                                <Table size={'small'}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell rowSpan={2}>ФИО экзаменатора, дисциплина</TableCell>
                                            <TableCell align={'center'} rowSpan={2}>Группа, кол-во студентов</TableCell>
                                            <TableCell align={'center'} rowSpan={2}>Не аттестовано, %</TableCell>
                                            <TableCell align={'center'} colSpan={3}>В т.ч.</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align={'center'}>н/д</TableCell>
                                            <TableCell align={'center'}>н/я</TableCell>
                                            <TableCell align={'center'}>неуд.</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data?.sort((l,r) => l.facultyName > r.facultyName ? 1 : -1).map((faculty) => {
                                            return <>
                                                <TableRow style={{'backgroundColor': 'lightgray'}}>
                                                    <TableCell colSpan={6} align={'center'}><strong>{faculty.facultyName}</strong></TableCell>
                                                </TableRow>
                                                {faculty.courses.sort((l,r) => l.courseName > r.courseName ? 1 : -1).map((course) => {
                                                    return <>
                                                        <TableRow style={{'backgroundColor': '#f6f6f6'}}>
                                                            <TableCell colSpan={6} align={'center'}><strong>{course.courseName}</strong> ({toDateString(course.examsFrom)} - {toDateString(course.examsTo)})</TableCell>
                                                        </TableRow>
                                                        {course.rows.sort((l,r) => l.groupName > r.groupName ? 1 : -1).map((row) => {
                                                            return <TableRow>
                                                                <TableCell>{row.employees.join(', ')}<br />{row.disciplineName}</TableCell>
                                                                <TableCell align={'center'}>{row.groupName} ({row.numberOfStudentsInGroup})<br />{toDateString(row.examDate)}</TableCell>
                                                                <TableCell align={'center'}>{row.numberOfNotPassedStudents}<br />{row.percentageOfNotPassedStudents.toFixed(2)}%</TableCell>

                                                                <TableCell align={'center'}>{row.numberOfNotAllowedGrades}</TableCell>
                                                                <TableCell align={'center'}>{row.numberOfNotAttendedGrades}</TableCell>
                                                                <TableCell align={'center'}>{row.numberOfBadGrades}</TableCell>
                                                            </TableRow>
                                                        })}
                                                    </>
                                                })}
                                            </>
                                        })}
                                    </TableBody>
                                </Table>
                            </Card>
                        </>
                        : <>
                            <Typography align={'center'} visibility={!isSaving}>Нет загруженных данных</Typography>
                        </>}
                    <br />
                </Stack>
            </Container>
        </GeneralLayout>
    );
}