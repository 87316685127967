import {Helmet} from 'react-helmet-async';
// @mui
import {
    Card,
    Stack,
    Container,
    Typography,
    TableContainer,
    CardContent,
    Button,
    CircularProgress,
    Box,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    FormControl, InputLabel, InputAdornment, OutlinedInput, FormControlLabel, Checkbox,
} from '@mui/material';

import {useNavigate, useParams} from 'react-router-dom';

// components
import Scrollbar from '../components/scrollbar';
// sections
import {StepByStepTable} from "../sections/@dashboard/attestations";
import {GeneralLayout} from "../layouts/GeneralLayout";
import Label from "../components/label";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import PageTitle from "../components/PageTitle";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {RemoveRedEye} from "@mui/icons-material";
import copyObject from "../utils/copyObject";
import Paper from "@mui/material/Paper";
import {
    useSessionResultsAtTheMomentOfPassingByFacultyGroupsWebReportMutation
} from "../store/reports/reportsApi";
import {useGetFacultiesQuery} from "../store/faculty/facultyApi";
import {useGetSemestersQuery} from "../store/system/systemApi";

export default function SessionResultsAtTheMomentOfPassingByFacultyGroupsWebReportPage() {
    const {id} = useParams();
    const navigate = useNavigate();
    const [isSaving, setIsSaving] = useState(false);
    const {data: semestersData} = useGetSemestersQuery();
    const {data: facultiesData} = useGetFacultiesQuery();
    const [dataRequest] = useSessionResultsAtTheMomentOfPassingByFacultyGroupsWebReportMutation();
    const [data, setData] = useState([]);
    const [semesterId, setSemesterId] = useState(null);
    const [facultyId, setFacultyId] = useState(0);
    const [useIntramuralForm, setUseIntramuralForm] = useState(true);
    const [useExtramuralForm, setUseExtramuralForm] = useState(true);
    const [useIntramuralExtramuralForm, setUseIntramuralExtramuralForm] = useState(true);

    function formatDate(dateObj) {
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }

    function toDateString(date) {
        if (date === null || date === undefined)
            return '';

        return new Date(date).toLocaleDateString("ru-RU");
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }

    const loadData = async () => {
        if (semesterId === null || semesterId === undefined) {
            alert('Выберите семестр')
            return;
        }
        
        setIsSaving(true);

        const request = {
            semesterId,
            facultyId: facultyId === 0 ? null : facultyId,
            addIntramuralForm: useIntramuralForm,
            addExtramuralForm: useExtramuralForm,
            addIntramuralExtramuralForm: useIntramuralExtramuralForm
        };
        console.log(request);

        await dataRequest({data: request}).unwrap().then((fulfilled) => {
            if (fulfilled?.success) {
                setData(copyObject(fulfilled.faculties))
            } else {
                fulfilled.errors?.map((item) => {
                    alert(item);
                    return 1;
                });
            }

            setIsSaving(false);
        }).catch((e) => {
            alert('Ошибка');
            setIsSaving(false);
        });
    };

    function avg(faculty, prop) {
        if (faculty.groups.length === 0)
            return 0;

        let sum = 0;
        faculty.groups.forEach((item) => {
            sum += item[prop];
        })

        return sum / faculty.groups.length;
    }

    function sum(faculty, prop) {
        let sum = 0;
        faculty.groups.forEach((item) => {
            sum += item[prop];
        })

        return sum;
    }

    return (
        <GeneralLayout>
            <PageTitle title={`Результаты сессии на момент ее сдачи (По группам факультетов)`}/>

            <Container>
                <Stack direction="column"
                       padding="120px 0px 0px 100px"
                       spacing={2}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {isSaving ? <CircularProgress/> : <></>}
                            Результаты сессии на момент ее сдачи (По группам факультетов)
                        </Typography>
                    </Stack>

                    <Box>
                        {/* Фильтр */}
                        <Card>
                            <CardContent>
                                <Stack spacing={2}>
                                    <Stack spacing={2} direction={'row'}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label" size={'small'}>Семестр</InputLabel>
                                            <Select size={'small'}
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    label="Семестр"
                                                    onChange={(event) => {setSemesterId(parseInt(event.target.value, 10))}}
                                            >
                                                {semestersData?.data.map((item) => {
                                                    return <MenuItem key={item.value} value={item.key}>{item.value}</MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>

                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label" size={'small'}>Факультет</InputLabel>
                                            <Select size={'small'}
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    label="Факультет"
                                                    defaultValue={0}
                                                    onChange={(event) => {setFacultyId(parseInt(event.target.value, 10))}}
                                            >
                                                <MenuItem key={0} value={0}>Все</MenuItem>
                                                {facultiesData?.data.map((item) => {
                                                    return <MenuItem key={item.value} value={item.key}>{item.value}</MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Stack>

                                    <Stack spacing={2} direction={'row'}>
                                        <FormControlLabel control={<Checkbox defaultChecked onChange={(event, checked) => {setUseExtramuralForm(checked)}} />} label="Заочная ф.о." />
                                        <FormControlLabel control={<Checkbox defaultChecked onChange={(event, checked) => {setUseIntramuralForm(checked)}}/>} label="Очная ф.о." />
                                        <FormControlLabel control={<Checkbox defaultChecked onChange={(event, checked) => {setUseIntramuralExtramuralForm(checked)}}/>} label="Очно-Заочная ф.о." />
                                    </Stack>
                                    
                                    <Button size={'small'} variant={'contained'} onClick={loadData}>Загрузить</Button>
                                </Stack>
                            </CardContent>
                        </Card>

                        <br/>

                        {/* Таблицы */}
                        {data?.map((faculty, index) => {
                            return <Box mb={4}>
                                <Typography variant="h6" gutterBottom>
                                    Факультет {faculty.facultyName}
                                </Typography>

                                <Card>
                                    <TableContainer>
                                        <Table size={'small'}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell rowSpan={2}>Группа</TableCell>
                                                    <TableCell rowSpan={2}>Всего человек</TableCell>
                                                    <TableCell colSpan={2}>"2"</TableCell>
                                                    <TableCell colSpan={2}>"н/я"</TableCell>
                                                    <TableCell colSpan={2}>"н/д"</TableCell>
                                                    <TableCell colSpan={2}>Всего не сдавших</TableCell>
                                                </TableRow>

                                                <TableRow>
                                                    <TableCell>Абс.</TableCell>
                                                    <TableCell>%</TableCell>

                                                    <TableCell>Абс.</TableCell>
                                                    <TableCell>%</TableCell>

                                                    <TableCell>Абс.</TableCell>
                                                    <TableCell>%</TableCell>

                                                    <TableCell>Абс.</TableCell>
                                                    <TableCell>%</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {faculty.groups.map((group) => {
                                                    return <>
                                                        <TableRow>
                                                            <TableCell>{group.groupName}</TableCell>
                                                            <TableCell>{group.numberOfStudentsInGroup}</TableCell>
                                                            <TableCell>{group.numberOfStudentsWithBadGrade}</TableCell>
                                                            <TableCell>{group.percentageOfStudentsWithBadGrade}</TableCell>
                                                            <TableCell>{group.numberOfStudentsWithNotAppearedGrade}</TableCell>
                                                            <TableCell>{group.percentageOfStudentsWithNotAppearedGrade}</TableCell>
                                                            <TableCell>{group.numberOfStudentsWithNotAllowedGrade}</TableCell>
                                                            <TableCell>{group.percentageOfStudentsWithNotAllowedGrade}</TableCell>
                                                            <TableCell>{group.numberOfNotPassedStudents}</TableCell>
                                                            <TableCell>{group.percentageOfNotPassedStudents}</TableCell>
                                                        </TableRow>
                                                    </>
                                                })}
                                                
                                                <TableRow style={{'backgroundColor': 'lightGray'}}>
                                                    <TableCell><strong>По факультету</strong></TableCell>
                                                    <TableCell>{sum(faculty, 'numberOfStudentsInGroup')}</TableCell>
                                                    <TableCell>{sum(faculty, 'numberOfStudentsWithBadGrade')}</TableCell>
                                                    <TableCell>{avg(faculty, 'percentageOfStudentsWithBadGrade')}</TableCell>
                                                    <TableCell>{sum(faculty, 'numberOfStudentsWithNotAppearedGrade')}</TableCell>
                                                    <TableCell>{avg(faculty, 'percentageOfStudentsWithNotAppearedGrade')}</TableCell>
                                                    <TableCell>{sum(faculty, 'numberOfStudentsWithNotAllowedGrade')}</TableCell>
                                                    <TableCell>{avg(faculty, 'percentageOfStudentsWithNotAllowedGrade')}</TableCell>
                                                    <TableCell>{sum(faculty, 'numberOfNotPassedStudents')}</TableCell>
                                                    <TableCell>{avg(faculty, 'percentageOfNotPassedStudents')}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Card>
                            </Box>
                        })}
                    </Box>

                    <br/>
                </Stack>
            </Container>
        </GeneralLayout>
    );
}