import {Helmet} from 'react-helmet-async';
// @mui
import {
    Card,
    Stack,
    Container,
    Typography,
    TableContainer,
    CardContent,
    Button,
    CircularProgress,
    Box,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    FormControl, InputLabel, InputAdornment, OutlinedInput, FormControlLabel, Checkbox,
} from '@mui/material';

import {useNavigate, useParams} from 'react-router-dom';

// components
import Scrollbar from '../components/scrollbar';
// sections
import {StepByStepTable} from "../sections/@dashboard/attestations";
import {GeneralLayout} from "../layouts/GeneralLayout";
import Label from "../components/label";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import PageTitle from "../components/PageTitle";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {RemoveRedEye} from "@mui/icons-material";
import copyObject from "../utils/copyObject";
import Paper from "@mui/material/Paper";
import {
    useAverageEmployeeGradesOverTheDisciplinesWebReportMutation,
    useNotAllowedStudentsWebReportMutation
} from "../store/reports/reportsApi";
import {useListAllChairsQuery} from "../store/chair/chairApi";

export default function NotAllowedStudentsWebReportPage() {
    const {id} = useParams();
    const navigate = useNavigate();
    const [isSaving, setIsSaving] = useState(false);
    const {data: chairsData} = useListAllChairsQuery(); 
    const [dataRequest] = useNotAllowedStudentsWebReportMutation();
    const [data, setData] = useState([]);
    const [chairs, setChairs] = useState([]);
    
    useEffect(() => {
        if (!chairsData)
            return;
        
        setChairs(copyObject(chairsData.chairs))
    }, [chairsData]);

    function formatDate(dateObj) {
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }

    function toDateString(date) {
        if (date === null || date === undefined)
            return '';

        return new Date(date).toLocaleDateString("ru-RU");
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }

    const loadData = async (chairId) => {
        setIsSaving(true);

        const request = {
            chairId: chairId === 0 ? null : chairId
        };
        console.log(request);

        await dataRequest({data: request}).unwrap().then((fulfilled) => {
            if (fulfilled?.success) {
                setData(copyObject(fulfilled.chairs))
            } else {
                fulfilled.errors?.map((item) => {
                    alert(item);
                    return 1;
                });
            }

            setIsSaving(false);
        }).catch((e) => {
            alert('Ошибка');
            setIsSaving(false);
        });
    };

    return (
        <GeneralLayout>
            <PageTitle title={`Не допущенные студенты очной ф.о. к сессии (по кафедрам)`}/>

            <Container>
                <Stack direction="column"
                       padding="120px 0px 0px 100px"
                       spacing={2}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {isSaving ? <CircularProgress/> : <></>}
                            Не допущенные студенты очной ф.о. к сессии (по кафедрам)
                        </Typography>
                    </Stack>

                    <Box>
                        {/* Фильтр */}
                        <Card>
                            <CardContent>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label" size={'small'}>Кафедра</InputLabel>
                                    <Select size={'small'}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Кафедра"
                                        onChange={(event) => {loadData(parseInt(event.target.value, 10))}}
                                    >
                                        <MenuItem key={0} value={0}>Все</MenuItem>
                                        {chairs?.sort((l,r) => l.value > r.value).map((chair) => {
                                            return <MenuItem key={chair.value} value={chair.key}>{chair.value}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </CardContent>
                        </Card>
                        
                        <br />

                        {/* Таблицы */}
                        {data?.map((chair, index) => (
                            <Box key={index} mb={4}>
                                <Typography variant="h6" gutterBottom>
                                    {chair.chairName}
                                </Typography>

                                <Card>
                                    <CardContent>
                                        {chair.years.map((year, yearIndex) => (
                                            <Box key={yearIndex} mb={3}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    Учебный год: {year.yearName}
                                                </Typography>

                                                <TableContainer>
                                                    <Table size={'small'}>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell rowSpan={2}>Сессия</TableCell>
                                                                <TableCell rowSpan={2}>Закрыто ведомостей
                                                                    (дисциплиногрупп)</TableCell>
                                                                <TableCell rowSpan={2}>Невовремя закрытых осн. ведомостей
                                                                    (дисциплиногрупп)</TableCell>
                                                                <TableCell rowSpan={2}>Своевременное закрытие</TableCell>
                                                                <TableCell rowSpan={2}>Всего человекодисциплин</TableCell>
                                                                <TableCell colSpan={2} align="center">
                                                                    Не допущено к сессии
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell align={'center'}>Абс.</TableCell>
                                                                <TableCell align={'center'}>%</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {year.sessions.map((session, sessionIndex) => (
                                                                <>
                                                                    <TableRow>
                                                                        <TableCell rowSpan={session.rows.length + 1}>
                                                                            {session.sessionName}
                                                                        </TableCell>
                                                                        <TableCell rowSpan={session.rows.length + 1}>
                                                                            {session.numberOfClosedStatements} из{" "}
                                                                            {session.totalNumberOfStatements}
                                                                        </TableCell>
                                                                        <TableCell rowSpan={session.rows.length + 1}>
                                                                            {session.numberOfOverduePrimaryStatements}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    {session.rows.map((row, rowIndex) => (
                                                                        <TableRow key={rowIndex}>
                                                                            <TableCell>{row.rowName}</TableCell>
                                                                            <TableCell>{row.numberOfPersonDisciplines}</TableCell>
                                                                            <TableCell
                                                                                align={'center'}>{row.numberOfNotAllowedStudents}</TableCell>
                                                                            <TableCell
                                                                                align={'center'}>{row.percentageOfNotAllowedStudents} %</TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Box>
                                        ))}
                                    </CardContent>
                                </Card>
                            </Box>
                        ))}
                    </Box>

                    <br/>
                </Stack>
            </Container>
        </GeneralLayout>
    );
}