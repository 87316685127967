import { Helmet } from 'react-helmet-async';
// @mui
import {
    Card,
    Stack,
    Container,
    Typography,
    TableContainer,
    CardContent,
    Button,
    CircularProgress,
    Box,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    FormControl, InputLabel, InputAdornment, OutlinedInput, FormControlLabel, Checkbox,
} from '@mui/material';

import {useNavigate, useParams} from 'react-router-dom';

// components
import Scrollbar from '../components/scrollbar';
// sections
import { StepByStepTable } from "../sections/@dashboard/attestations";
import {GeneralLayout} from "../layouts/GeneralLayout";
import Label from "../components/label";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import PageTitle from "../components/PageTitle";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {RemoveRedEye} from "@mui/icons-material";
import copyObject from "../utils/copyObject";
import Paper from "@mui/material/Paper";
import {useAverageEmployeeGradesOverTheDisciplinesWebReportMutation} from "../store/reports/reportsApi";

export default function AverageEmployeeGradesOverTheDisciplinesWebReportPage() {
    const {id} = useParams();
    const navigate = useNavigate();
    const [isSaving, setIsSaving] = useState(false);
    const [dataRequest] = useAverageEmployeeGradesOverTheDisciplinesWebReportMutation();
    const [data, setData] = useState([]);
    const [examsDate, setExamsDate] = useState(null);
    const [noOutsidePlanPasses, setNoOutsidePlanPasses] = useState(false);
    const [noRetakes, setNoRetakes] = useState(false);

    function formatDate(dateObj) {
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }

    function toDateString(date){
        if (date === null || date === undefined)
            return '';

        return new Date(date).toLocaleDateString("ru-RU");
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }

    const loadData = async () => {
        setIsSaving(true);

        const request = {
            noRetakes,
            noOutsidePlanPasses
        };
        console.log(request);

        await dataRequest({data: request}).unwrap().then((fulfilled) => {
            if (fulfilled?.success) {
                setData(copyObject(fulfilled.faculties))
            } else {
                fulfilled.errors?.map((item) => {
                    alert(item);
                    return 1;
                });
            }

            setIsSaving(false);
        }).catch((e) => {
            alert('Ошибка');
            setIsSaving(false);
        });
    };
    
    function avgOverEmployee(employee, type) {
        if (employee.disciplines.length === 0)
            return 0;
        
        let sum = 0;
        employee.disciplines.forEach((item) => {
            if (type === "grade") {
                sum += item.avgGrade;
            } else {
                sum += item.percentageOfAttendedStudents;
            }
        })
        
        console.log('sum',sum);
        return (sum / employee.disciplines.length).toFixed(2);
    }

    function avgOverChair(chair, type) {
        let sum = 0;
        let numberOfItems = 0;
        chair.employees.forEach((employee) => {
            if (employee.disciplines.length === 0)
                return;

            employee.disciplines.forEach((item) => {
                if (type === "grade") {
                    sum += item.avgGrade;
                } else {
                    sum += item.percentageOfAttendedStudents;
                }
                
                numberOfItems += 1;
            })
        });
        
        if (numberOfItems === 0)
            return 0;

        return (sum / numberOfItems).toFixed(2);
    }

    return (
        <GeneralLayout>
            <PageTitle title={`Статистика пересдач по университету`} />

            <Container>
                <Stack direction="column"
                       padding="120px 0px 0px 100px"
                       spacing={2}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {isSaving ? <CircularProgress /> : <></>}
                            Статистика пересдач по университету
                        </Typography>
                    </Stack>

                    <Card>
                        <CardContent>
                            <Box display="flex" alignItems="center" gap={2}>
                                <FormControlLabel
                                    control={<Checkbox />}
                                    onChange={(event, checked) => setNoRetakes(checked)}
                                    label="Не учитывать пересдачи"
                                />
                                <FormControlLabel
                                    control={<Checkbox />}
                                    onChange={(event, checked) => setNoOutsidePlanPasses(checked)}
                                    label="Не учитывать досрочные и внеплановые сдачи"
                                />
                                <Button variant="contained" onClick={loadData}>
                                    Просмотр
                                </Button>
                            </Box>
                        </CardContent>
                    </Card>
                    
                    {/* Таблицы кафедр */}
                    {data?.chairs?.map((chair, index) => (
                        <Box key={index} mb={4}>
                            <Typography variant="h6" gutterBottom>
                                {chair.chairName}
                            </Typography>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Преподаватель</TableCell>
                                            <TableCell>Дисциплина</TableCell>
                                            <TableCell>Средний балл</TableCell>
                                            <TableCell>Посещаемость на экзамене</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {chair.employees?.map((employee, empIndex) => (
                                            <>
                                                {employee.disciplines?.map((discipline, discIndex) => (
                                                    <TableRow key={discIndex}>
                                                        {discIndex === 0 && (
                                                            <TableCell
                                                                rowSpan={employee.disciplines.length + (employee.disciplines.length > 1 ? 1 : 0)}
                                                                style={{ verticalAlign: "top" }}
                                                            >
                                                                {employee.employeeFullName}
                                                            </TableCell>
                                                        )}
                                                        <TableCell>
                                                            {discipline.disciplineName} (
                                                            {discipline.courseName})
                                                        </TableCell>
                                                        <TableCell>{discipline.avgGrade}</TableCell>
                                                        <TableCell>
                                                            {discipline.percentageOfAttendedStudents}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                                {employee.disciplines.length > 1
                                                ? <TableRow style={{'backgroundColor': '#F0F0F0'}}>
                                                        <TableCell><strong>По всем дисциплинам</strong></TableCell>
                                                        <TableCell><strong>{avgOverEmployee(employee, 'grade')}</strong></TableCell>
                                                        <TableCell><strong>{avgOverEmployee(employee, 'percentage')}%</strong></TableCell>
                                                    </TableRow>
                                                : <></>}
                                            </>
                                        ))}
                                        <TableRow style={{'backgroundColor': 'lightgray'}}>
                                            <TableCell colSpan={2}><strong>По кафедре</strong></TableCell>
                                            <TableCell><strong>{avgOverChair(chair, 'grade')}</strong></TableCell>
                                            <TableCell><strong>{avgOverChair(chair, 'percentage')}%</strong></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    ))}

                    <br />
                </Stack>
            </Container>
        </GeneralLayout>
    );
}