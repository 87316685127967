import { Helmet } from 'react-helmet-async';
// @mui
import {
    Card,
    Stack,
    Container,
    Typography,
    TableContainer, CardContent, Button, CircularProgress, Box,
} from '@mui/material';

import {useNavigate, useParams} from 'react-router-dom';

// components
import Scrollbar from '../components/scrollbar';
// sections
import { StepByStepTable } from "../sections/@dashboard/attestations";
import {GeneralLayout} from "../layouts/GeneralLayout";
import Label from "../components/label";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {useGetNextAttestationNumberQuery, usePostCreateAttestationMutation} from "../store/attestation/attestationApi";
import PageTitle from "../components/PageTitle";
import {SchoolOutlined} from "@mui/icons-material";

export default function ReportsListPage() {
    const navigate = useNavigate();
    const reports = [
        {
            url: '/debtor-students-report',
            title: 'Студенты-задолжники',
            icon: <SchoolOutlined />
        }
    ];

    return (
        <GeneralLayout>
            <PageTitle title={`Список отчётов`} />

            <Container>
                <Stack direction="column"
                       padding="120px 0px 0px 100px"
                       spacing={2}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            Список отчётов
                        </Typography>
                    </Stack>

                    <Card>
                        <CardContent>
                            <Stack spacing={2}>
                                {reports.map((item) => {
                                    return <Button variant={'outlined'}
                                                   size={'small'}
                                                   href={item.url}
                                                   startIcon={item.icon}>{item.title}</Button>
                                })}
                            </Stack>
                        </CardContent>
                    </Card>
                </Stack>
            </Container>
        </GeneralLayout>
    );
}