import {useNavigate, useParams} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import {
    Button,
    ButtonGroup,
    Card,
    CardContent, CircularProgress,
    Container,
    Input,
    Stack,
    Table,
    TableBody,
    TableCell, TableHead,
    TableRow, ToggleButton, ToggleButtonGroup,
    Typography
} from "@mui/material";
import Scrollbar from "../components/scrollbar";
import {GeneralLayout} from "../layouts/GeneralLayout";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {useGetSessionForCourseQuery, usePostSaveSessionMutation} from "../store/session/sessionApi";
import PageTitle from "../components/PageTitle";

export default function CreateSessionPage() {
    const {id, flowNumber} = useParams();
    const dateDefaultValue = 'NaN-NaN-NaN';
    const navigate = useNavigate();
    const [refresh, setRefresh] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [restrictDataSaving, setRestrictDataSaving] = useState(false); // obsolete
    const [saveSession] = usePostSaveSessionMutation();

    const {data} = useGetSessionForCourseQuery({courseId: id, flowNumber: flowNumber ?? null});
    const [copyOfData, setCopyOfData] = useState({});

    useEffect(() => {
        if (data === null || data === undefined)
            return;

        setCopyOfData(copyData(data?.data));
        applyVisualChanges();
    }, [data]);
    
    function checkIfAllDataIsSet() {
        const controlTypesFilled = copyOfData?.disciplines?.filter((item) => item.controlType === null).length === 0;
        const extraControlTypesFilled = copyOfData?.disciplines?.filter((item) => item.extraControlType === 0).length === 0;
        // костыль
        const datesAreSet = copyOfData?.checksStartDate !== dateDefaultValue 
            && copyOfData?.checksEndDate !== dateDefaultValue
            && copyOfData?.examsStartDate !== dateDefaultValue
            && copyOfData?.examsEndDate !== dateDefaultValue;
        console.log(datesAreSet);
        console.log(copyOfData?.checksStartDate);
        
        console.log(`controlTypes: ${controlTypesFilled}. Extra: ${extraControlTypesFilled}. Dates: ${datesAreSet}`)
        
        setRestrictDataSaving(!controlTypesFilled || !datesAreSet);
    }

    function applyVisualChanges() {
        setRefresh(!refresh);
        checkIfAllDataIsSet();
    }

    function copyData(source) {
        const date = new Date();
        return {
            sessionId: source?.sessionId,
            courseId: parseInt(id, 10),
            checksStartDate: formatDate(source !== null ? new Date(`${source?.checksStartDate}`) : date),
            checksEndDate: formatDate(source !== null ? new Date(`${source?.checksEndDate}`) : date),
            examsStartDate: formatDate(source !== null ? new Date(`${source?.examsStartDate}`) : date),
            examsEndDate: formatDate(source !== null ? new Date(`${source?.examsEndDate}`) : date),
            holidaysStartDate: formatDate(source !== null ? new Date(`${source?.holidaysStartDate}`) : date),
            holidaysEndDate: formatDate(source !== null ? new Date(`${source?.holidaysEndDate}`) : date),
            disciplines: source?.disciplines.map((item) => {
                return copyDiscipline(item);
            }),
            flowNumber: source.flowNumber
        };
    }

    function copyDiscipline(source) {
        return {
            disciplineId: source?.disciplineId,
            disciplineName: source?.disciplineName,
            controlType: source?.controlType,
            extraControlType: source?.extraControlType
        }
    }

    function formatDate(dateObj) {
        if (dateObj === null || dateObj === undefined)
            return null;
        
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }

    function changeControlType(obj, value) {
        obj.controlType = parseInt(value, 10);
        applyVisualChanges();
    }

    function changeExtraControlType(obj, value) {
        if (obj.extraControlType === parseInt(value, 10))
            obj.extraControlType = 0;
        else
            obj.extraControlType = parseInt(value, 10);

        applyVisualChanges();
    }

    const handleSave = async () => {
        setIsSaving(true);

        if (copyOfData.holidaysStartDate === dateDefaultValue)
            copyOfData.holidaysStartDate = '1970-01-01';

        if (copyOfData.holidaysEndDate === dateDefaultValue)
            copyOfData.holidaysEndDate = '1970-01-01';
        
        await saveSession({session: copyOfData}).unwrap()
            .then((fulfilled) => navigate('/sessions'))
            .catch((ex) => {
                alert('Ошибка');
                console.error(ex);
                setIsSaving(false);
            });
    };

    return <GeneralLayout>
        <PageTitle title={`${data?.sessionId > 0 ? "Редактировать" : "Создать"} сессию`} />

        <Container>
            <Stack
                padding="120px 0px 0px 100px"
                direction="column"
                spacing={2}
            >
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        {copyOfData?.sessionId > 0 ? "Редактировать" : "Создать"} сессию для {data?.data?.courseName}
                    </Typography>
                </Stack>

                <Card>
                    <CardContent>
                        <Stack spacing="20px">
                            <Stack direction="row" spacing="10px" alignContent="center" alignItems="center">
                                <Typography>Зачетная неделя с</Typography>
                                <TextField variant="outlined"
                                           type="date"
                                           disabled={copyOfData?.canEdit === false}
                                           value={copyOfData?.checksStartDate}
                                           onChange={(target) => {
                                               copyOfData.checksStartDate = target.target.value;
                                               applyVisualChanges()
                                           }}
                                           placeholder="Дата начала"/>

                                <Typography>по</Typography>

                                <TextField variant="outlined"
                                           type="date"
                                           value={copyOfData?.checksEndDate}
                                           disabled={copyOfData?.canEdit === false}
                                           onChange={(target) => {
                                               copyOfData.checksEndDate = target.target.value;
                                               applyVisualChanges()
                                           }}
                                           placeholder="Дата окончания"/>
                            </Stack>

                            <Stack direction="row" spacing="10px" alignContent="center" alignItems="center">
                                <Typography>Экзамены с</Typography>
                                <TextField variant="outlined"
                                           type="date"
                                           value={copyOfData?.examsStartDate}
                                           disabled={copyOfData?.canEdit === false}
                                           onChange={(target) => {
                                               copyOfData.examsStartDate = target.target.value;
                                               applyVisualChanges()
                                           }}
                                           placeholder="Дата начала"/>

                                <Typography>по</Typography>

                                <TextField variant="outlined"
                                           type="date"
                                           value={copyOfData?.examsEndDate}
                                           disabled={copyOfData?.canEdit === false}
                                           onChange={(target) => {
                                               copyOfData.examsEndDate = target.target.value;
                                               applyVisualChanges()
                                           }}
                                           placeholder="Дата окончания"/>
                            </Stack>

                            <Stack direction="row" spacing="10px" alignContent="center" alignItems="center">
                                <Typography>Каникулы с</Typography>
                                <TextField variant="outlined"
                                           type="date"
                                           value={copyOfData?.holidaysStartDate}
                                           disabled={copyOfData?.canEdit === false}
                                           onChange={(target) => {
                                               copyOfData.holidaysStartDate = target.target.value;
                                               applyVisualChanges()
                                           }}
                                           placeholder="Дата начала"/>

                                <Typography>по</Typography>

                                <TextField variant="outlined"
                                           type="date"
                                           value={copyOfData?.holidaysEndDate}
                                           disabled={copyOfData?.canEdit === false}
                                           onChange={(target) => {
                                               copyOfData.holidaysEndDate = target.target.value;
                                               applyVisualChanges()
                                           }}
                                           placeholder="Дата окончания"/>
                            </Stack>

                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Дисциплина</TableCell>
                                        <TableCell>Тип контроля</TableCell>
                                        <TableCell>Дополнительный контроль</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {copyOfData?.disciplines?.map((item) => {
                                        return (
                                            <TableRow>
                                                <TableCell>{item.disciplineName}</TableCell>
                                                <TableCell>
                                                    <ToggleButtonGroup orientation="horizontal"
                                                                       fullWidth
                                                                       exclusive
                                                                       value={item.controlType}
                                                                       onChange={(target) => {
                                                                           changeControlType(item, target.target.value)
                                                                       }}
                                                                       disabled={copyOfData?.canEdit === false}
                                                                       aria-label="Тип контроля"
                                                                       color="primary">
                                                        <ToggleButton value={0} area-label="Экзамен"
                                                                      color="primary">Экзамен</ToggleButton>
                                                        <ToggleButton value={1} area-label="Зачёт"
                                                                      color="primary">Зачёт</ToggleButton>
                                                        <ToggleButton value={2} area-label="Дифф. зачёт"
                                                                      color="primary">Дифф. зачёт</ToggleButton>
                                                    </ToggleButtonGroup>
                                                </TableCell>
                                                <TableCell>
                                                    <ToggleButtonGroup orientation="horizontal"
                                                                       exclusive
                                                                       disabled={copyOfData?.canEdit === false}
                                                                       value={item.extraControlType}
                                                                       onChange={(target) => {
                                                                           changeExtraControlType(item, target.target.value)
                                                                       }}
                                                                       aria-label="Дополнительный контроль"
                                                                       color="primary">
                                                        <ToggleButton value={3} area-label="Экзамен" color="primary">Курсовая
                                                            работа</ToggleButton>
                                                        <ToggleButton value={4} area-label="Зачёт" color="primary">Курсовой
                                                            проект</ToggleButton>
                                                    </ToggleButtonGroup>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                            <Stack justifyContent="space-between">
                                {
                                    !isSaving
                                        ? <Button variant="contained" 
                                                  onClick={handleSave}
                                                  disabled={copyOfData?.canEdit === false}
                                            >Сохранить</Button>
                                        : <CircularProgress/>
                                }
                            </Stack>
                            

                        </Stack>
                    </CardContent>
                </Card>
            </Stack>
        </Container>
    </GeneralLayout>
}