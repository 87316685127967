import PropTypes from "prop-types";
import {
    Backdrop,
    Button, ButtonGroup,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardHeader, CircularProgress, Divider,
    IconButton,
    Typography
} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import EditIcon from "@mui/icons-material/Edit";
import PrintIcon from "@mui/icons-material/Print";
import AddIcon from "@mui/icons-material/Add";
import {Link} from "react-router-dom";
import {useState} from "react";

FacultyStatementCard.propTypes = {
    statementId: PropTypes.number,
    statementNumber: PropTypes.string,
    closingDate: PropTypes.object,
    orderNumber: PropTypes.number,
    canCreate: PropTypes.bool,
    sessionDisciplineDetailId: PropTypes.number,
    eventDateTime: PropTypes.object,
    passingType: PropTypes.number,
    controlType: PropTypes.number
}

export default function FacultyStatementCard(props){
    const baseUrl = localStorage.getItem("baseUrl");
    const isClosed = props.closingDate !== null;
    const roles = localStorage.getItem('roles').split(',');
    const isUmu = roles.find((item) => item === 'AttNewUmu') !== undefined;
    const isDispatcher = roles.find((item) => item === 'AttNewDispatcher') !== undefined;
    const [draftIsLoading, setDraftIsLoading] = useState(false);
    const [dekanatStatementReportIsLoading, setDekanatStatementReportIsLoading] = useState(false);
    const isFaculty = roles.find((item) => item === 'faculty') !== undefined;

    function toDateString(date){
        if (date === null)
            return '';
        
        return new Date(date).toLocaleDateString("ru-RU");
    }
    
    function printDekanatStatementReport() {
        setDekanatStatementReportIsLoading(true);
        fetch(`/Reports/DekanatStatementReport?statementId=${props.statementId}`,
            {
                method: "GET",
                headers: { "semesterid": localStorage.getItem("selectedSemesterId"), "Content-Type": "application/json", "Authorization": `Bearer ${localStorage.getItem('token')}`}
            }).then(response => {
            setDekanatStatementReportIsLoading(false);
            return response.blob();
        }).then(response => {
            const url = window.URL.createObjectURL(
                new Blob([response]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `Отчет.pdf`,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
        })
    }
    
    function downloadStatementDraft() {
        setDraftIsLoading(true);
        fetch(`/Reports/StatementDraft?statementId=${props.statementId}`,
            {
                method: "GET",
                headers: { "semesterid": localStorage.getItem("selectedSemesterId"), "Content-Type": "application/json", "Authorization": `Bearer ${localStorage.getItem('token')}`}
            }).then(response => {
                setDraftIsLoading(false);
                return response.blob();
        }).then(response => {
                const url = window.URL.createObjectURL(
                    new Blob([response]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `Справочная ведомость.pdf`,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            })
    }

    function isItMoreThan1DayFromNow(eventDateTime) {
        return (new Date(eventDateTime) - new Date()) > 86400000 * 2;
    }

    return (
        <Card variant="outlined">
            <CardContent>
                {props.statementId > 0
                ? <>
                        <Typography variant="h6">{props.closingDate !== undefined && props.statementId > 0
                            ? `${(props.closingDate !== null ? 'Закрытая' : 'Открытая')} ведомость`
                            : <></>} № {props.statementNumber}</Typography>

                        <br />

                        {props.closingDate !== null ? <>
                            <strong>Дата проведения: {new Date(props.eventDateTime).toLocaleDateString('ru-RU')}</strong>
                        </> : <></> }                        
                    </>
                    : <></>}

                {props.statementId === 0 && !props.canCreate
                ? <Typography fontSize="small">Для создания новой ведомости необходимо закрыть предыдущую</Typography>
                    : <></>}

                {props.canCreate && isItMoreThan1DayFromNow(props.eventDateTime) && props.controlType === 0 && (props.passingType === 0 || props.passingType === 1)
                    ? <strong>Ведомость выписывается не раньше, чем за 1 рабочий день до начала сроков проведения ({new Date(props.eventDateTime).toLocaleDateString('ru-RU')})</strong>
                    : <></>}

                <ButtonGroup size="small" orientation="vertical">
                    {props.statementId > 0 
                    ? <Button startIcon={<InsertDriveFileOutlinedIcon />}
                              href={`/statementresults/${props.statementId}`}>Подробности и результаты</Button>
                    : <></>}
                    
                    {props.statementId > 0 && props.closingDate == null && !isUmu && !isDispatcher
                        ? <Button startIcon={<EditIcon />}
                                  href={`/editfacultystatement/${props.statementId}`}>Изменить ведомость</Button>
                        : <></>
                    }

                    {props.statementId > 0
                        ? <Button startIcon={<PrintIcon />} 
                                  disabled={draftIsLoading}
                                  onClick={() => downloadStatementDraft()}>Справочная ведомость</Button>
                        : <></>}

                    {props.statementId > 0
                        ? <Button startIcon={dekanatStatementReportIsLoading ? <CircularProgress size={15}/> : <PrintIcon />} onClick={()=>printDekanatStatementReport()}>Отчет</Button>
                        : <></>}

                    {props.statementId === 0 && props.canCreate && !(isItMoreThan1DayFromNow(props.eventDateTime) && props.controlType === 0 && (props.passingType === 0 || props.passingType === 1))
                        ? <>
                            {props.eventDateTime !== null ? <>
                                <strong>Дата проведения: {new Date(props.eventDateTime).toLocaleDateString('ru-RU')}</strong>

                                <br />
                            </> : <></>}

                                {isFaculty
                                    ? <Button
                                        variant="contained"
                                        startIcon={<AddIcon />}
                                        href={`/createstatement/${props.sessionDisciplineDetailId}`}> Создать</Button>
                                    : <>Ведомость не создана</>}               
                        </>
                    : <></>}
                </ButtonGroup>

                {draftIsLoading
                ? <>
                        <Divider/>
                        <br/>
                        <CircularProgress size={"2rem"}/>
                    </>
                    : <></>}
            </CardContent>
        </Card>
    )
}