import { Helmet } from 'react-helmet-async';
// @mui
import {
    Card,
    Stack,
    Container,
    Typography,
    TableContainer,
    CardContent,
    Button,
    CircularProgress,
    Box,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    FormControl, InputLabel,
} from '@mui/material';

import {useNavigate, useParams} from 'react-router-dom';

// components
import Scrollbar from '../components/scrollbar';
// sections
import { StepByStepTable } from "../sections/@dashboard/attestations";
import {GeneralLayout} from "../layouts/GeneralLayout";
import Label from "../components/label";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {useGetNextAttestationNumberQuery, usePostCreateAttestationMutation} from "../store/attestation/attestationApi";
import PageTitle from "../components/PageTitle";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {RemoveRedEye} from "@mui/icons-material";
import {useAcademicStipendWebReportMutation, useSessionResultsWebReportMutation} from "../store/reports/reportsApi";
import copyObject from "../utils/copyObject";
import {useGetFacultiesQuery, useGetGroupsByFacultyMutation} from "../store/faculty/facultyApi";
import {useGetSemestersQuery} from "../store/system/systemApi";

export default function AcademicStipendReportPage() {
    const {id} = useParams();
    const navigate = useNavigate();
    const [isSaving, setIsSaving] = useState(false);
    const [dataRequest] = useAcademicStipendWebReportMutation();
    const [groupsRequest] = useGetGroupsByFacultyMutation();
    const [data, setData] = useState([]);
    const {data: facultiesData} = useGetFacultiesQuery();
    const {data: semestersData} = useGetSemestersQuery();
    const [selectedFacultyId, setSelectedFacultyId] = useState(null);
    const [selectedGroupId, setSelectedGroupId] = useState(null);
    const [selectedSemesterId, setSelectedSemesterId] = useState(null);
    const [groups, setGroups] = useState([]);
    const [financialForm, setFinancialForm] = useState(0);
    const [collectingDate, setCollectingDate] = useState(null);

    function formatDate(dateObj) {
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }

    function toDateString(date){
        if (date === null || date === undefined)
            return '';

        return new Date(date).toLocaleDateString("ru-RU");
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }
    
    async function loadGroups(facultyId) {
        console.log(facultyId)
        setIsSaving(true);

        const request = {
            facultyId
        };
        console.log(request);

        await groupsRequest({data: request}).unwrap().then((fulfilled) => {
            if (fulfilled?.success) {
                setGroups(copyObject(fulfilled.data))
            } else {
                fulfilled.errors.map((item) => {
                    alert(item);
                    return 1;
                });
            }

            setIsSaving(false);
        }).catch((e) => {
            alert('Ошибка');
            setIsSaving(false);
        });
    }

    const loadData = async () => {
        if (collectingDate === null || collectingDate === undefined) {
            alert('Заполните дату сбора')
            return;
        }
        
        if (selectedSemesterId === null || selectedSemesterId === undefined) {
            alert('Выберите семестр');
            return;
        }
        
        setIsSaving(true);

        const request = {
            facultyId: selectedFacultyId,
            groupId: selectedGroupId,
            financialForm,
            semesterId: selectedSemesterId,
            byDate: collectingDate
        };
        console.log(request);

        await dataRequest({data: request}).unwrap().then((fulfilled) => {
            if (fulfilled?.success) {
                setData(copyObject(fulfilled.data))
            } else {
                fulfilled.errors.map((item) => {
                    alert(item);
                    return 1;
                });
            }

            setIsSaving(false);
        }).catch((e) => {
            alert('Ошибка');
            setIsSaving(false);
        });
    };

    return (
        <GeneralLayout>
            <PageTitle title={`Академическая стипендия`} />

            <Container>
                <Stack direction="column"
                       padding="120px 0px 0px 100px"
                       spacing={2}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {isSaving ? <CircularProgress /> : <></>}
                            Академическая стипендия
                        </Typography>
                    </Stack>
                    
                    <Stack alignItems={'start'}>
                        <Button href={`/edit-finance-forms`}>Редактировать выплаты</Button>
                    </Stack>

                    <Card>
                        <CardContent>
                            <Stack direction={'row'} spacing={2} alignItems={'end'} alignContent={'space-between'}>
                                <FormControl fullWidth>
                                    <InputLabel id="faculty-select-label"
                                                size={'small'}>Выберите факультет</InputLabel>
                                    <Select
                                        labelId={'faculty-select-label'}
                                        label={'Выберите факультет'}
                                        size={'small'}
                                        onChange={(event) => {setSelectedFacultyId(parseInt(event.target.value,10)); setSelectedGroupId(null); loadGroups(parseInt(event.target.value,10))}}>
                                        {facultiesData?.data?.map((item) => {
                                            return <MenuItem key={item.value} value={item.key}>{item.value}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>

                                <FormControl fullWidth>
                                    <InputLabel id="faculty-select-label" size={'small'}>Группа</InputLabel>
                                    <Select
                                        labelId={'faculty-select-label'}
                                        label={'Группа'}
                                        size={'small'}
                                        onChange={(event) => {setSelectedGroupId(parseInt(event.target.value,10))}}>
                                        {groups?.map((item) => {
                                            return <MenuItem key={item.value} value={item.key}>{item.value}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>

                                <FormControl fullWidth>
                                    <InputLabel id="faculty-select-label" size={'small'}>Семестр</InputLabel>
                                    <Select
                                        labelId={'faculty-select-label'}
                                        label={'Семестр'}
                                        size={'small'}
                                        onChange={(event) => {setSelectedSemesterId(parseInt(event.target.value,10))}}>
                                        {semestersData?.data?.map((item) => {
                                            return <MenuItem key={item.value} value={item.key}>{item.value}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Stack>
                            <Stack direction={'row'} spacing={2} alignItems={'end'}>
                                <FormControl fullWidth>
                                    <InputLabel id="faculty-select-label" size={'small'}>Форма финансирования</InputLabel>
                                    <Select
                                        labelId={'faculty-select-label'}
                                        label={'Форма финансирования'}
                                        defaultValue={0}
                                        size={'small'}
                                        onChange={(event) => {setFinancialForm(event.target.value)}}>
                                        <MenuItem key={0} value={0}>Все</MenuItem>
                                        <MenuItem key={1} value={1}>Обучающиеся за счет бюджетных средств</MenuItem>
                                        <MenuItem key={2} value={2}>Обучающиеся на договорной основе</MenuItem>
                                    </Select>
                                </FormControl>
                                
                                <Stack>
                                    <Typography fontSize={'small'}>Дата сбора</Typography>
                                    <TextField type={'date'} size={'small'} onChange={(event) => setCollectingDate(event.target.value)}/>
                                </Stack>
                            </Stack>
                            
                            <Stack>
                                <br />
                                <Button variant={'contained'}
                                        startIcon={<RemoveRedEye />}
                                        onClick={loadData}
                                        disabled={isSaving}>Загрузить</Button>
                            </Stack>
                        </CardContent>
                    </Card>

                    {data !== null && data !== undefined && data.length > 0
                        ? <>
                            <Card>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>№ п/п</TableCell>
                                            <TableCell align={'center'}>Факультет</TableCell>
                                            <TableCell align={'center'}>Группа</TableCell>
                                            <TableCell align={'center'}>ФИО обучающегося</TableCell>
                                            <TableCell align={'center'}>Размер стипендии в месяц, руб.</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.map((item, index) => {
                                            return <TableRow>
                                                <TableCell>{index+1}</TableCell>
                                                <TableCell align={'center'}>{item.facultyName}</TableCell>
                                                <TableCell align={'center'}>{item.groupName}</TableCell>
                                                <TableCell align={'center'}>{item.studentFullName}</TableCell>
                                                <TableCell align={'center'}>{item.amount}</TableCell>
                                            </TableRow>
                                        })}
                                    </TableBody>
                                </Table>
                            </Card>
                        </>
                        : <>
                            <Typography align={'center'} visibility={!isSaving}>Нет загруженных данных</Typography>
                        </>}
                    <br />
                </Stack>
            </Container>
        </GeneralLayout>
    );
}