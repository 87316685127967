import { Helmet } from 'react-helmet-async';
// @mui
import {
    Card,
    Stack,
    Container,
    Typography,
    TableContainer,
    CardContent,
    Button,
    CircularProgress,
    Box,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    FormControl, InputLabel,
} from '@mui/material';

import {useNavigate, useParams} from 'react-router-dom';

// components
import Scrollbar from '../components/scrollbar';
// sections
import { StepByStepTable } from "../sections/@dashboard/attestations";
import {GeneralLayout} from "../layouts/GeneralLayout";
import Label from "../components/label";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {useGetNextAttestationNumberQuery, usePostCreateAttestationMutation} from "../store/attestation/attestationApi";
import PageTitle from "../components/PageTitle";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {RemoveRedEye} from "@mui/icons-material";
import {
    useAcademicStipendWebReportMutation,
    useOverdueStatementsWebReportMutation,
    useSessionResultsWebReportMutation
} from "../store/reports/reportsApi";
import copyObject from "../utils/copyObject";
import {useGetFacultiesQuery, useGetGroupsByFacultyMutation} from "../store/faculty/facultyApi";
import {useGetSemestersQuery} from "../store/system/systemApi";

export default function OverdueStatementsWebReport() {
    const {id} = useParams();
    const navigate = useNavigate();
    const [isSaving, setIsSaving] = useState(false);
    const [dataRequest] = useOverdueStatementsWebReportMutation();
    const [data, setData] = useState([]);
    
    const [status, setStatus] = useState(0);
    const [educationForm, setEducationForm] = useState(0);
    const [type, setType] = useState(0);

    function formatDate(dateObj) {
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }

    function toDateString(date){
        if (date === null || date === undefined)
            return '';

        return new Date(date).toLocaleDateString("ru-RU");
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }

    const loadData = async () => {
        if (educationForm === null || educationForm === undefined
            || type === null || type === undefined
            || status === null || status === undefined) {
            alert('Заполните фильтры');
            return;
        }

        setIsSaving(true);

        const request = {
            closingState: status,
            passingType: type,
            educationForm: educationForm === 0 ? null : educationForm
        };
        console.log(request);

        await dataRequest({data: request}).unwrap().then((fulfilled) => {
            if (fulfilled?.success) {
                setData(copyObject(fulfilled.data))
            } else {
                fulfilled.errors.map((item) => {
                    alert(item);
                    return 1;
                });
            }

            setIsSaving(false);
        }).catch((e) => {
            alert('Ошибка');
            setIsSaving(false);
        });
    };

    return (
        <GeneralLayout>
            <PageTitle title={`Ведомости, закрытые с опозданием`} />

            <Container>
                <Stack direction="column"
                       padding="120px 0px 0px 100px"
                       spacing={2}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {isSaving ? <CircularProgress /> : <></>}
                            Ведомости, закрытые с опозданием
                        </Typography>
                    </Stack>

                    <Card>
                        <CardContent>
                            <Stack direction={'row'} spacing={2} alignItems={'end'} alignContent={'space-between'}>
                                <FormControl fullWidth>
                                    <InputLabel id="faculty-select-label"
                                                size={'small'}>Статус</InputLabel>
                                    <Select
                                        labelId={'faculty-select-label'}
                                        label={'Статус'}
                                        size={'small'}
                                        defaultValue={0}
                                        onChange={(event) => {setStatus(parseInt(event.target.value,10));}}>
                                        <MenuItem key={'все'} value={0}>Все</MenuItem>
                                        <MenuItem key={'еще не закрытые'} value={1}>Еще не закрытые</MenuItem>
                                        <MenuItem key={'закрытые с опозданием'} value={2}>Закрытые с опозданием</MenuItem>
                                    </Select>
                                </FormControl>

                                <FormControl fullWidth>
                                    <InputLabel id="faculty-select-label"
                                                size={'small'}>Тип</InputLabel>
                                    <Select
                                        labelId={'faculty-select-label'}
                                        label={'Тип'}
                                        size={'small'}
                                        defaultValue={0}
                                        onChange={(event) => {setType(parseInt(event.target.value,10));}}>
                                        <MenuItem key={'Любой тип'} value={0}>Любой тип</MenuItem>
                                        <MenuItem key={'Экзамены'} value={1}>Экзамены</MenuItem>
                                        <MenuItem key={'Пересдачи'} value={2}>Пересдачи</MenuItem>
                                        <MenuItem key={'Курсовые работы'} value={3}>Курсовые работы</MenuItem>
                                    </Select>
                                </FormControl>

                                <FormControl fullWidth>
                                    <InputLabel id="faculty-select-label"
                                                size={'small'}>Форма обучения</InputLabel>
                                    <Select
                                        labelId={'faculty-select-label'}
                                        label={'Форма обучения'}
                                        size={'small'}
                                        defaultValue={0}
                                        onChange={(event) => {setEducationForm(parseInt(event.target.value,10));}}>
                                        <MenuItem key={'Все формы'} value={0}>Все формы</MenuItem>
                                        <MenuItem key={'Заочная'} value={1}>Заочная</MenuItem>
                                        <MenuItem key={'Очная'} value={2}>Очная</MenuItem>
                                        <MenuItem key={'Очно-Заочная'} value={3}>Очно-Заочная</MenuItem>
                                    </Select>
                                </FormControl>
                            </Stack>

                            <Stack>
                                <br />
                                <Button variant={'contained'}
                                        startIcon={<RemoveRedEye />}
                                        onClick={loadData}
                                        disabled={isSaving}>Загрузить</Button>
                            </Stack>
                        </CardContent>
                    </Card>

                    {data !== null && data !== undefined && data.length > 0
                        ? data.map((item) => {
                            return <>
                                <Typography variant={'h5'}>{item.passingTypeName}</Typography>
                                <Card>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>ФИО экзаменатора / предмет</TableCell>
                                                <TableCell align={'center'}>Группа</TableCell>
                                                <TableCell align={'center'}>№ ведомости</TableCell>
                                                <TableCell align={'center'}>Дата создания ведомости факультетом</TableCell>
                                                <TableCell align={'center'}>Дата экзамена</TableCell>
                                                <TableCell align={'center'}>Фактическая дата закрытия ведомости</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {item.faculties.map((faculty) => {
                                                return <>
                                                    <TableRow>
                                                        <TableCell colSpan={6}>{faculty.facultyName}</TableCell>
                                                    </TableRow>
                                                    {faculty.courses.map((course) => {
                                                        return <>
                                                            <TableRow>
                                                                <TableCell colSpan={6}>{course.courseName}</TableCell>
                                                            </TableRow>
                                                            {course.rows.map((row) => {
                                                                return <TableRow>
                                                                    <TableCell>{row.employees.join(', ')}<br />{row.disciplineName}</TableCell>
                                                                    <TableCell align={'center'}>{row.groupName}</TableCell>
                                                                    <TableCell align={'center'}>{!row.statementNumber ? 'Не создана' : <Button href={`/statementresults/${row.statementId}`}>{row.statementNumber}</Button>}</TableCell>
                                                                    <TableCell align={'center'}>{!row.statementCreationDate ? 'Не создана' : toDateString(row.statementCreationDate)}</TableCell>
                                                                    <TableCell align={'center'}>{!row.eventDate ? 'Не создана' : toDateString(row.eventDate)}</TableCell>
                                                                    <TableCell align={'center'}>{!row.closingDate ? 'Не создана' : toDateString(row.closingDate)}</TableCell>
                                                                </TableRow>
                                                            })}
                                                        </>
                                                    })}
                                                </>
                                            })}
                                        </TableBody>
                                    </Table>
                                </Card>
                            </>
                        })
                        : <>
                            <Typography align={'center'} visibility={!isSaving}>Нет загруженных данных</Typography>
                        </>}
                    <br />
                </Stack>
            </Container>
        </GeneralLayout>
    );
}