import { Helmet } from 'react-helmet-async';
// @mui
import {
    Card,
    Stack,
    Container,
    Typography,
    TableContainer, CardContent, Button, CircularProgress, Box, Table, TableHead, TableRow, TableCell, TableBody,
} from '@mui/material';

import {useNavigate, useParams} from 'react-router-dom';

// components
import Scrollbar from '../components/scrollbar';
// sections
import { StepByStepTable } from "../sections/@dashboard/attestations";
import {GeneralLayout} from "../layouts/GeneralLayout";
import Label from "../components/label";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {useGetNextAttestationNumberQuery, usePostCreateAttestationMutation} from "../store/attestation/attestationApi";
import PageTitle from "../components/PageTitle";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {RemoveRedEye} from "@mui/icons-material";
import {useSessionResultsWebReportMutation} from "../store/reports/reportsApi";
import copyObject from "../utils/copyObject";

export default function SessionResultsReportPage() {
    const {id} = useParams();
    const navigate = useNavigate();
    const [isSaving, setIsSaving] = useState(false);
    const [dataRequest] = useSessionResultsWebReportMutation();
    const [data, setData] = useState([]);
    const [primaryStatementsOnly, setPrimaryStatementsOnly] = useState(1);
    const [educationForm, setEducationForm] = useState(0);
    const [courseType, setCourseType] = useState('-');
    const [isBudget, setIsBudget] = useState(1);
    const courseTypes = [
        {
            key: '-',
            value: 'Все'
        },
        {
            key: 'Б',
            value: 'Академический бакалавриат'
        },
        {
            key: 'П',
            value: 'Прикладной бакалавриат'
        },
        {
            key: 'С',
            value: 'Специалитет'
        },
        {
            key: 'М',
            value: 'Магистратура'
        },
        {
            key: 'А',
            value: 'Аспирантура'
        },
    ];

    function formatDate(dateObj) {
        return `${dateObj.getFullYear()}-${withZero(dateObj.getMonth() + 1)}-${withZero(dateObj.getDate())}`;
    }

    function toDateString(date){
        if (date === null || date === undefined)
            return '';

        return new Date(date).toLocaleDateString("ru-RU");
    }

    function withZero(num) {
        return num < 10 ? `0${num}` : num;
    }
    
    function avgNumber(arr) {
        if (arr.length === 0)
            return 0;
        
        let sum = 0;
        arr.forEach((item) => {
            sum += item;
        });
        
        return (sum / arr.length).toFixed(2);
    }
    
    function avgOfCourse(courseNum, type) {
        if (!data?.length)
            return 0;
        
        let sum = 0;
        let numberOfItems = 0;
        data.forEach((faculty) => {
            faculty.courses.filter((item) => courseNum === null || item.courseNumber === courseNum).forEach((item) => {
                if (type === 'grade') {
                    sum += item.avgGrade;
                } 
                else {
                    sum += item.percentageOfSuccessfullyPassed;
                }
                
                numberOfItems+=1;
            })
        });
        
        if (numberOfItems === 0)
            return 0;
        
        return (sum / numberOfItems).toFixed(2);
    }

    const loadData = async () => {
        setIsSaving(true);

        const request = {
            primaryStatementsOnly: primaryStatementsOnly === 2,
            educationForm: educationForm === 0 ? null : educationForm,
            courseType: courseType === '-' ? null : courseType,
            isBudget: isBudget === 1
        };
        console.log(request);
        
        await dataRequest({data: request}).unwrap().then((fulfilled) => {
            if (fulfilled?.success) {
                setData(copyObject(fulfilled.faculties))
            } else {
                fulfilled.errors.map((item) => {
                    alert(item);
                    return 1;
                });
            }
            
            setIsSaving(false);
        }).catch((e) => {
            alert('Ошибка');
            setIsSaving(false);
        });
    };
    
    function getCourseNumbers() {
        const list = [];
        data.forEach((faculty) => {
            faculty.courses.forEach((course) => {
                if (list.find((item) => item === course.courseNumber) === undefined) {
                    list.push(course.courseNumber);
                }
            })
        })
        
        return list.sort((l,r) => l > r ? 1 : -1);
    }

    return (
        <GeneralLayout>
            <PageTitle title={`Результаты сессии`} />

            <Container>
                <Stack direction="column"
                       padding="120px 0px 0px 100px"
                       spacing={2}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            {isSaving ? <CircularProgress /> : <></>}
                            Результаты сессии
                        </Typography>
                    </Stack>
                    
                    <Card>
                        <CardContent>
                            <Stack direction={'row'} spacing={2} alignItems={'end'}>
                                <Stack>
                                    <Typography><strong>Формировать</strong></Typography>
                                    <Select size={'small'} defaultValue={1} onChange={(event) => {setPrimaryStatementsOnly(event.target.value)}}>
                                        <MenuItem key={1} value={1}>С учётом пересдач</MenuItem>
                                        <MenuItem key={2} value={2}>Только основные ведомости</MenuItem>
                                    </Select>
                                </Stack>

                                <Stack>
                                    <Typography><strong>Форма обучения</strong></Typography>
                                    <Select size={'small'} defaultValue={0} onChange={(event) => {setEducationForm(event.target.value)}}>
                                        <MenuItem key={0} value={0}>Все</MenuItem>
                                        <MenuItem key={1} value={1}>Заочная</MenuItem>
                                        <MenuItem key={2} value={2}>Очная</MenuItem>
                                        <MenuItem key={3} value={3}>Очно-Заочная</MenuItem>
                                    </Select>
                                </Stack>

                                <Stack>
                                    <Typography><strong>Уровень образования</strong></Typography>
                                    <Select size={'small'} defaultValue={'-'} onChange={(event) => {setCourseType(event.target.value)}}>
                                        {courseTypes.map((item) => {
                                            return <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>
                                        })}
                                    </Select>
                                </Stack>

                                <Stack>
                                    <Typography><strong>Форма финансирования</strong></Typography>
                                    <Select size={'small'} defaultValue={1} onChange={(event) => {setIsBudget(event.target.value)}}>
                                        <MenuItem key={1} value={1}>Обучающиеся за счет бюджетных средств</MenuItem>
                                        <MenuItem key={0} value={0}>Обучающиеся на договорной основе</MenuItem>
                                    </Select>
                                </Stack>
                            </Stack>
                            <Stack>
                                <br />
                                <Button variant={'contained'} 
                                        startIcon={<RemoveRedEye />}
                                        onClick={loadData} 
                                        disabled={isSaving}>Загрузить</Button>
                            </Stack>
                        </CardContent>
                    </Card>

                    {data !== null && data !== undefined && data.length > 0
                    ? <>
                            <Typography variant={'h5'}>По университету</Typography>

                            <Card>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align={'center'}>Курс</TableCell>
                                            <TableCell align={'center'}>Средний балл</TableCell>
                                            <TableCell align={'center'}>Процент сдавших</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {getCourseNumbers().map((courseNum) => {
                                            return <TableRow>
                                                <TableCell align={'center'}>{courseNum}</TableCell>
                                                <TableCell align={'center'}>{avgOfCourse(courseNum, 'grade')}</TableCell>
                                                <TableCell align={'center'}>{avgOfCourse(courseNum, 'percentage')}%</TableCell>
                                            </TableRow>
                                        })}

                                        <TableRow style={{'backgroundColor': 'lightgrey'}}>
                                            <TableCell align={'center'}><strong>ИТОГО</strong></TableCell>
                                            <TableCell align={'center'}>{avgOfCourse(null, 'grade')}</TableCell>
                                            <TableCell align={'center'}>{avgOfCourse(null, 'percentage')}%</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Card>

                            <br />
                            <Typography variant={'h5'}>По факультетам</Typography>

                            <Card>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align={'center'}>Факультет</TableCell>
                                            <TableCell align={'center'}>Курс</TableCell>
                                            <TableCell align={'center'}>Средний балл</TableCell>
                                            <TableCell align={'center'}>Процент сдавших</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data?.sort((l,r) => l.facultyName > r.facultyName ? 1 : -1).map((faculty) => {
                                            const sortedCourses = faculty.courses.sort((l,r) => l.courseNumber > r.courseNumber ? 1 : -1);
                                            const firstElement = sortedCourses[0];
                                            return <>
                                                <TableRow>
                                                    <TableCell align={'center'} rowSpan={faculty.courses.length+1}><strong>{faculty.facultyName}</strong></TableCell>
                                                    <TableCell align={'center'}>{firstElement?.courseNumber}</TableCell>
                                                    <TableCell align={'center'}>{firstElement?.avgGrade.toFixed(2)}</TableCell>
                                                    <TableCell align={'center'}>{firstElement?.percentageOfSuccessfullyPassed.toFixed(2)}%</TableCell>
                                                </TableRow>
                                                {sortedCourses.slice(1).map((item) => {
                                                    return <TableRow>
                                                        <TableCell align={'center'}>{item?.courseNumber}</TableCell>
                                                        <TableCell align={'center'}>{item?.avgGrade.toFixed(2)}</TableCell>
                                                        <TableCell align={'center'}>{item?.percentageOfSuccessfullyPassed.toFixed(2)}%</TableCell>
                                                    </TableRow>
                                                })}

                                                <TableRow style={{'backgroundColor': 'lightgrey'}}>
                                                    <TableCell align={'center'}><strong>ИТОГ</strong></TableCell>
                                                    <TableCell align={'center'}><strong>{avgNumber(faculty.courses.map((item) => {
                                                        return item.avgGrade
                                                    }))}</strong></TableCell>
                                                    <TableCell align={'center'}><strong>{avgNumber(faculty.courses.map((item) => {
                                                        return item.percentageOfSuccessfullyPassed
                                                    }))}%</strong></TableCell>
                                                </TableRow>
                                            </>
                                        })}
                                    </TableBody>
                                </Table>
                            </Card>
                        </>
                    : <>
                        <Typography align={'center'} visibility={!isSaving}>Нет загруженных данных</Typography>
                        </>}
                    <br />
                </Stack>
            </Container>
        </GeneralLayout>
    );
}